export const numberFormats = {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'de-DE': {
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    }
}