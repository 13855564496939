import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/components/pages/Home.vue'
import Aktuell from '@/components/pages/Aktuell.vue'
import Archiv from '@/components/pages/Archiv.vue'
import Jahresgaben from '@/components/pages/Jahresgaben.vue'
import Mitgliedschaft from '@/components/pages/Mitgliedschaft.vue'
import Shop from '@/components/pages/Shop.vue'
import Text from '@/components/pages/Text.vue'
import Search from '@/components/pages/Search.vue'
import Detail from '@/components/pages/Detail.vue'
import DetailGift from '@/components/pages/DetailGift.vue'
import DetailShop from '@/components/pages/DetailShop.vue'
import {store} from '@/centralStore'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/programm/aktuell',
        name: 'aktuell',
        component: Aktuell
    },
    {
        path: '/programm/archiv',
        name: 'archiv',
        component: Archiv
    },
    ///////////////////// Nur als Vorschlag //////////////////
    {
        path: '/programm/:slug',
        name: 'detail',
        component: Detail
    },
    
    {
        path: '/jahresgaben/:slug',
        name: 'detailGift',
        component: DetailGift
    },
    {
        path: '/shop/:slug',
        name: 'detailShop',
        component: DetailShop
    },
    //////////////////////////////////////////////////////////
    {
        path: '/jahresgaben',
        name: 'jahresgaben',
        component: Jahresgaben
    },
    {
        path: '/ueber/agenda',
        name: 'agenda',
        component: Text
    },
    {
        path: '/ueber/team',
        name: 'team',
        component: Text
    },
    {
        path: '/ueber/geschichte',
        name: 'geschichte',
        component: Text
    },
    {
        path: '/mitgliedschaft',
        name: 'mitgliedschaft',
        component: Mitgliedschaft
    },
    {
        path: '/shop',
        name: 'shop',
        component: Shop
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: Text
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: Text
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: Text
    },
    {
        path: '/search/:searchQuery?',
        name: 'search',
        component: Search
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name != 'home') store.startscreen = false
    store.orderVisible = false
    store.tinkerRoute = to
    next()
})

export default router
