<template>
<div id="overlay">
    <div v-if="!success">
    <header>
        <h1>{{ $t("title.bestellung") }}</h1>
        <button @click="toggleOrder"><h4>&#xF0011;</h4></button>
    </header>
    <div v-if="order">
        <div><img :src="order.images[0].thumbnail_small"></div>
        <div>
            <ul>
                <li v-for='artist in order.artists' :key='artist'><h2>{{artist}}</h2></li>
            </ul>
            <h2><em>{{ getAttr(order, 'title') }}</em></h2>
            <h2 v-if="order.unique">(Unikat {{ parseInt(order.pk) + 1 }}/{{ order.maxUnique }})</h2>
            <h2 v-if="order.price">{{ $n(order.price, 'currency', getLocale()) }}</h2>
            <h2 v-else>{{ $t('title.gratis') }}</h2>
        </div>
    </div>
    <form v-on:submit.prevent="submitForm">
        <input type="text" v-model="form.first_name" :placeholder="$t('order.vorname')" required>
        <input type="text" v-model="form.last_name" :placeholder="$t('order.name')" required>
        <input type="email" v-model="form.email" :placeholder="$t('order.email')" required>
        <input type="text" v-if="order.gift" v-model="form.membership" :placeholder="$t('order.mitgliedsnummer')" required>
        <div class="select">
            <select v-model="form.shipping" required>
                <option disabled selected value="">{{$t('order.versandart')}}</option>
                <option v-for="option in form.shipping_options" :value="option" v-bind:key="option">
                    {{$t('order.' + option.toLowerCase())}}
                </option>
            </select>
            <label><p>&#8595;</p></label>
        </div>
        <div v-if="form.shipping == 'Versand'">
            <input type="text" v-model="form.street" :placeholder="$t('order.strasse')" required>
            <input type="text" v-model="form.zip" :placeholder="$t('order.postleitzahl')" required>
            <input type="text" v-model="form.city" :placeholder="$t('order.stadt')" required>
            <input type="text" v-model="form.country" :placeholder="$t('order.land')" required>
        </div>

        <input type="text" v-model="form.phone" :placeholder="$t('order.telefon')" required>
        <textarea v-model="form.notes" :placeholder="$t('order.bemerkung')"></textarea>
        <button @click="pseudoval($event)" type="submit"><h3>{{ $t('title.bestellen')}}</h3></button>
    </form>
    <h4>{{ $t('text.bestellung')}}</h4>
    <h4 class="error">{{ $t('order.error') }}</h4>
    </div>
    <div v-else>
        <header>
            <h1>{{$t('text.danke_a')}}</h1>
            <button @click="toggleOrder">&#x2715;</button>
        </header>
        <h1>{{$t('text.danke_b')}}</h1>
    </div>
</div>
</template>



<script>
import api from '@/api'
import {store} from '@/centralStore'
import functions from '@/functions.js'

export default {
    name: 'OrderForm',
    props: ['order'],
    data(){
        return{
            store: store,
            element: this.order,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                membership: '',
                shipping: '', 
                shipping_options: [
                    'Versand',
                    'Abholung'
                ],
                street: '',
                zip: '',
                city: '',
                country: '',
                phone: '',
                notes: '',
                price: this.order.price,
                element: this.order.title_de,
                artists: this.order.artists,
                unique: this.order.unique,
                unique_id: parseInt(this.order.pk) + 1,
                unique_max: this.order.maxUnique,

            },
            success: false
        }
    },
    methods: {
        toggleOrder(){
            this.store.orderVisible = !this.store.orderVisible
            this.store.element = null
            //console.log(this.orders)
        },
        pseudoval(element){
            element.target.parentElement.classList.add('firsttry')
        },
        submitForm(){
            api.post('submit-order/', this.form)
                .then(response => {
                    //console.log(response)
                    if ( response.status == '200' )
                        this.success = true
                })
        },
        getCat: functions.getCat,
        getAttr: functions.getAttr,
        getLocale: functions.getLocale
    },
    mounted () {
        document.body.classList.add('no-scroll')
    },
    unmounted () {
        document.body.classList.remove('no-scroll')
    }
}



</script>


<style scoped>
#overlay{
    position: fixed; 
    overflow-y: scroll;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0,0,0,0.06);
}

#overlay > div {
    width: calc(100% - (var(--margin-green) + .125rem) * 2);
    border-radius: .625rem;
    background: var(--bg);
    padding: calc(var(--margin-green) + .125rem);
    min-height: calc(100% - (var(--margin-green) + .125rem) * 2);
    margin: calc(var(--margin-green) + .125rem);
    /* -webkit-box-shadow: 0px 0px 10px 0px #000029; 
    box-shadow: 0px 0px 10px 0px #000029; */
}

.desktop #overlay > div {
    width: 33.333%;
}

.desktop.xxl #overlay > div {
    width: 25%;
}

header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--margin-pink) - .5rem);
}

header > h1 {
    margin-top: -.1825rem;
    font-variation-settings: "wght" 90, "hght" 536, "prtn" 0;
    font-feature-settings: "ss01" 0, "ss02" 0, "ss04" 0;
}

header > button {
    width: var(--button);
    height: var(--button);
    border-radius: var(--button);
    padding: 0;
    padding-bottom: .0625rem;
    flex-shrink: 0;
    background: var(--bg);
    color: var(--text);
}

header > button:hover {
    background: var(--text);
    color: var(--bg);
}

header + div {
    display: flex;
    margin-bottom: calc(var(--margin-yellow) + .25rem);
}

header + div img {
    width: 5rem;
    height: auto;
    padding-top: .1875rem;
    margin-right: calc(var(--margin-green) / 2);
}

h2 > em::after {
    content: ',\00a0';
}

a[role="button"]{
    cursor: pointer;
}

input, select, textarea {
    width: 100%;
    margin-bottom: calc(var(--margin-green) + .125rem);
    color: var(--text);
}

.select {
    position: relative;
}

.select label {
    position: absolute;
    font-size: var(--micro-fontsize);
    top: .15rem;
    right: .75rem;
    pointer-events: none;
}

textarea {
    padding-top: var(--margin-orange);
    min-height: 8.2rem;
    border-radius: calc(var(--base-fontsize) / 2);
    margin-bottom: calc(var(--margin-green) - .0625rem);
}

*::placeholder {
    color: var(--text);
    opacity: 1;
}

button {
    width: 100%;
    background: var(--text);
    color: var(--bg);
}
form {
    margin-bottom: calc(var(--margin-yellow) + .25rem);
}

button[type="submit"] > h3 {
    pointer-events: none;
}

form.firsttry input:invalid, form.firsttry select:invalid {
    border-color: red;
}

form.firsttry input:invalid::placeholder, form.firsttry select:invalid, form.firsttry select:invalid + label {
    color: red;
}

form + h4 + h4 {
    display: none;
    margin-top: var(--margin-green);
}

form.firsttry:invalid + h4 + h4 {
    display: block;
    color: red;
}

</style>
