
<template>
    <nav>
        <ul>
            <li><h2><router-link :to="{name:'home'}" @click="closeMenu">{{$t("title.home")}}</router-link></h2></li>
            <ul :class="{'router-link-active': subIsActive('/programm')}">
                <li><h2><a role="button" tabindex="0" @click="toggleSub($event.currentTarget.closest('ul'))">{{$t("title.programm")}}</a></h2></li>
                <li><h2><router-link :to="{name: 'aktuell'}" @click="closeMenu">{{$t("title.aktuell")}}</router-link></h2></li>
                <li><h2><router-link :to="{name: 'archiv'}" @click="closeMenu">{{$t("title.archiv")}}</router-link></h2></li>
            </ul>
            <li><h2><router-link :to="{name: 'jahresgaben'}" @click="closeMenu" :class="{'router-link-active': subIsActive('/jahresgaben')}">{{$t("title.jahresgaben")}}</router-link></h2></li>
            <ul :class="{'router-link-active': subIsActive('/ueber')}">
                <li><h2><a role="button" tabindex="0" @click="toggleSub($event.currentTarget.closest('ul'))">{{$t("title.ueber")}}</a></h2></li>
                <li><h2><router-link :to="{name: 'agenda'}" @click="closeMenu">{{$t("title.agenda")}}</router-link></h2></li>
                <li><h2><router-link :to="{name: 'team'}" @click="closeMenu">{{$t("title.team")}}</router-link></h2></li>
                <li><h2><router-link :to="{name: 'geschichte'}" @click="closeMenu">{{$t("title.geschichte")}}</router-link></h2></li>
            </ul>
            <li><h2><router-link :to="{name: 'mitgliedschaft'}" @click="closeMenu">{{$t("title.mitgliedschaft")}}</router-link></h2></li>
            <li><h2><router-link :to="{name: 'shop'}" @click="closeMenu" :class="{'router-link-active': subIsActive('/shop')}">{{$t("title.shop")}}</router-link>
            </h2></li>
        </ul>
        <form @keyup.enter="closeMenu">
            <input id="search" type="text" v-model="searchQuery" @change="doSearch" :class="$i18n.locale" :placeholder="$t('title.search')">
            <label role="button" @click="closeMenu">&#8594;</label>
        </form>
        <ul>
            <li><h2>
                <a v-if="$i18n.locale == 'de'" href="https://app.getresponse.com/site2/kvhbf_hp_newsletter/?u=SGzde&webforms_id=mhQN" target="_blank" @click="closeMenu">Newsletter</a>
                <a v-else href="https://app.getresponse.com/site2/kvhbf_hp_newsletter/?u=SGzde&webforms_id=Siqlm" target="_blank" @click="closeMenu">Newsletter</a>
            </h2></li>
            <li><h2><router-link :to="{name:'kontakt'}" @click="closeMenu">{{$t("title.kontakt")}}</router-link></h2></li>
            <li><h2><router-link :to="{name:'impressum'}" @click="closeMenu">{{$t("title.impressum")}}</router-link></h2></li>
            <li><h2><router-link :to="{name:'datenschutz'}" @click="closeMenu">{{$t("title.datenschutz")}}</router-link></h2></li>
        </ul>
        <div><button role="button" @click="switchLang"><h3>{{ otherLang }}</h3></button></div>
        <Footer></Footer>
    </nav>
</template>

<script>
import Footer from '@/components/snippets/Footer'
import {store} from '@/centralStore'

export default {
    inject: ["mq"],
    components: {
        Footer,
    },
    data(){
        return {
            store: store,
            searchQuery: '',
        }
    },
    computed: {
        otherLang(){
            if (this.$i18n.locale == 'de'){
                return 'en'
            } else {
                return 'de'
            }
        }
    },
    methods : {
        closeMenu(){
            //if (this.mq.current == 'xs' || this.mq.current == 'sm')
                store.menuVisible = false;
            /*
            const activeSubs = document.querySelectorAll('ul.router-link-active')
            for (const element of activeSubs) {
                element.classList.remove('router-link-active')
            }
            */
            
        },
        switchLang(){
            if (this.$i18n.locale == 'de'){
                this.$i18n.locale = 'en'
            } else {
                this.$i18n.locale = 'de'
            }
            localStorage.setItem('lang', this.$i18n.locale)
        },
        doSearch(){
            this.store.lastRoute = (this.$route.name == 'search') ? '/' : this.$route.fullPath
            let query = this.searchQuery;
            if (query != ''){
                this.$router.push({
                    name: 'search', 
                    params: {searchQuery: query}
                })
                this.store.search = query
                //this.searchQuery = ''
            }
            

        },
        toggleSub(element){
            const active = 'router-link-active';
            if(!element.classList.contains(active)){
                const activeRouter = document.querySelectorAll('nav ul.' + active)
                for (const element of activeRouter) {
                    element.classList.remove(active)
                }
                element.classList.add(active)
                /*
                const activeRouter = document.querySelectorAll('nav > ul a.' + active)
                for (const element of activeRouter) {
                    element.classList.remove(active)
                }
                */
            }else{
                element.classList.remove(active)
            }
            
        },
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
            return this.$route.path.indexOf(path) === 0
            })
        },
    },
    watch: { 
        '$route.params.searchQuery': {
            handler: function() {
                if(this.$route.params.searchQuery == undefined)
                    this.searchQuery = ''
            },
            deep: true,
            immediate: true
        }
    }
}
</script>


<style scoped>
nav {
    color: var(--text);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: .3125rem;
}

nav h2 {
    font-feature-settings: "ss01", "ss02", "ss04";
    font-variation-settings: "wght" 90, "hght" 491, "prtn" 500;
}

nav > footer {
    margin-top: 0;
}

a {
    display: inline-flex;
    align-items: baseline;
    color: var(--text);
}

[role="button"]{
    cursor: pointer;
    outline: none;
}

a::before{
    content: '';
    border: .0625rem solid var(--border);
    width: calc( var(--heading-fontsize) - var(--heading-fontsize) / 4 );
    height: calc( var(--heading-fontsize) - var(--heading-fontsize) / 4 );
    border-radius: calc( var(--heading-fontsize) - var(--heading-fontsize) / 4 );
    margin-right: .4rem;
    flex-shrink: 0;
}

 .desktop nav a::before {
    opacity: 0;
    margin-right: -.9375rem;
    transition: .2s;
 }

 .desktop nav:hover a::before{
     opacity: 1;
     margin-right: .4rem;
 }

a:hover::before, a.router-link-active::before, ul.router-link-active a[role="button"]::before{
    background: var(--text);
}

nav ul ul.router-link-active li:not(:first-child) {
    display: block;
}

nav ul ul li:not(:first-child) {
    margin-left: calc( var(--base-fontsize) - var(--base-fontsize) / 4  + .5rem);
    display: none;
}

button{
    text-transform: uppercase;
}

form {
    position: relative;
}

form, div {
    margin: var(--margin-green) 0 calc(var(--margin-green) - .125rem) 0; 
}

label{
    position: absolute;
    top: calc(var(--small-fontsize) / 5 );
    right: .625rem;
    display: none;
}

input{
    width: 4.0625rem;
    padding-bottom: .125rem;
}

input.en{
    width: 4.5rem;
}

input::placeholder{
    color: var(--text);
    opacity: 1;
}

input:focus::placeholder {
  color: transparent;
}

input:hover:placeholder-shown::placeholder{
    color: var(--bg);
}
input:placeholder-shown:hover {
    background: var(--text);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus{
    -webkit-text-fill-color: var(--bg);
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
} 

input:focus, 
input:not(:placeholder-shown){
    outline: none;
    width: 100%;
    background: var(--text);
    color: var(--bg);
}

input:focus ~ label, input:not(:placeholder-shown) ~ label{
  display: block;
  color: var(--bg);
}

</style>
