<template>
<div>
    <router-link :to="{ name: getType(), params: { slug: event.slug  } }">
        <ul>
            <li v-for='artist in event.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2><em>{{ getAttr(event, 'title') }}</em></h2>
        <h2 v-if="event.price && event.available">{{ $n(event.price, 'currency', getLocale()) }}</h2>
        <h2 v-else-if="!event.price && event.available">{{ $t('title.gratis') }}</h2>
        <h2 v-else>({{ $t('title.vergriffen') }})</h2>
        <button disabled v-if="type == 'ShopItem'"><h3>{{ getCat(event.category) }}</h3></button>
        <button disabled v-else-if="$route.name == 'home'"><h3>{{$t('cat.jahresgabe')}}</h3></button>
        <figure>
            <!-- <v-lazy-image :src-placeholder="event.thumbnail_tiny" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" /> -->
            <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(event)}"></div>
            <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" alt="">
            <figcaption v-if="event.caption_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'caption')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.caption_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'caption')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <div v-else class="spacer"></div>
        </figure>
    </router-link>
</div>
</template>
<script>

import functions from '@/functions.js'
//import VLazyImage from 'v-lazy-image'

export default{
    inject: ["mq"],
    name: 'Item',
    props: ['event', 'tag', 'type'],
    components: {
        //VLazyImage
    },
    data(){
        return {
            imageLoaded: false
        }
    },
    methods: {
        getType: function(){
            if(this.type == 'ShopItem')
                return 'detailShop'
            else
                return 'detailGift'
        },
        getAspect(image){
            let aspect = (image.image_height / image.image_width) * 100;
            return aspect + '%'

        },
        getCat: functions.getCat,
        getAttr: functions.getAttr,
        getLocale: functions.getLocale
    },

}

</script>

<style scoped>
div:not(:last-of-type) > a { 
    margin-bottom: calc(var(--margin-yellow) + .625rem);
}

div > a { 
    color: black; 
    display: block;
}

time, button {
    display: block;
}

button {
    margin: var(--margin-green) 0 calc(var(--margin-green) + .125rem) 0; 
}

img {
    width: 100%;
}

figure {
    margin-top: var(--margin-green);
}

figcaption {
    font-size: var(--micro-fontsize);
    display: flex;
}

.v-lazy-image {
  filter: blur(.125rem);
}

.v-lazy-image-loaded {
  filter: none;
}

div.spacer{
    margin-bottom: .1875rem;
}

.placeholder{
    background-color: lightgrey;
    width: 100% !important;
    height: 0px !important;
    position: unset !important;
}

</style>
