<template>
<li :class="{'no-img': !img }">
    <router-link v-if="event.class == 'Event'" :to="{ name: 'detail', params: { slug: event.slug  } }">
        <h2><time v-if="event.end_date">{{ getDate(event.start_date, event.end_date) }}</time></h2>
        <h2 v-if="event.group_show"><em>{{ getAttr(event, 'title') }}</em></h2>
        <ul v-if="event.display_artists">
            <li v-for='artist in event.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2 v-if="!event.group_show"><em>{{ getAttr(event, 'title') }}</em></h2>
        <button v-if="tag" disabled><h3>{{ getCat(event.category) }}</h3></button>
        <button v-else disabled><h3>{{$t("cat.vorschau")}}</h3></button>
        <figure v-if="img && !event.group_show">
            <!-- <v-lazy-image :src-placeholder="event.thumbnail_tiny" :src="(mq.mdPlus) ? event.thumbnail_large :event.thumbnail_small" /> -->
            <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(event)}"></div>
            <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" alt="">
            <figcaption v-if="event.text_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.text_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
        </figure>
        <figure v-else-if="img && event.group_show">
            <!-- <v-lazy-image :src-placeholder="event.images[0].thumbnail_tiny" :src="(mq.mdPlus) ? event.images[0].thumbnail_large :event.images[0].thumbnail_small" /> -->
            <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(event.images[0])}"></div>
            <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? event.images[0].thumbnail_large : event.images[0].thumbnail_small" alt="">
            <figcaption v-if="event.text_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.text_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
        </figure>
    </router-link>
    <router-link v-else-if="event.class == 'AnnualGift'" :to="{ name: 'detailGift', params: { slug: event.slug  } }">
       <ul>
            <li v-for='artist in event.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2><em>{{ getAttr(event, 'title') }}</em></h2>
        <h2 v-if="event.price && event.available">{{ $n(event.price, 'currency', getLocale()) }}</h2>
        <h2 v-else>({{ $t('title.vergriffen') }})</h2>
        <button disabled><h3>{{$t('cat.jahresgabe')}}</h3></button>      
        <figure v-if="img">
            <!-- <v-lazy-image :src-placeholder="event.thumbnail_tiny" :src="(mq.mdPlus) ? event.thumbnail_large :event.thumbnail_small" /> -->
            <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(event)}"></div>
            <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" alt="">
            <figcaption v-if="event.caption_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'caption')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.caption_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'caption')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
        </figure>
    </router-link>
    <router-link v-else :to="{ name: 'detailShop', params: { slug: event.slug  } }">
        <ul>
            <li v-for='artist in event.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2><em>{{ getAttr(event, 'title') }}</em></h2>
        <h2 v-if="event.price && event.available">{{ $n(event.price, 'currency', getLocale()) }}</h2>
        <h2 v-else>({{ $t('title.vergriffen') }})</h2>
        <button disabled><h3>{{ getCat(event.category) }}</h3></button>
        <figure v-if="img">
            <!-- <v-lazy-image :src-placeholder="event.thumbnail_tiny" :src="(mq.mdPlus) ? event.thumbnail_large :event.thumbnail_small" /> -->
            <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(event)}"></div>
            <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" alt="">
            <figcaption v-if="event.caption_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'caption')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.caption_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'caption')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
        </figure>      
    </router-link>
</li>
</template>

<script>
import functions from '@/functions.js'
//import VLazyImage from 'v-lazy-image'

export default{
    inject: ["mq"],
    name: 'ElementShort',
    props: ['event', 'img', 'tag'],
    components: {
        //VLazyImage
    },
    data(){
        return {
            imageLoaded: false
        }
    },
    methods: {
        getAttr: functions.getAttr,
        getDate: functions.getDate,
        getCat: functions.getCat,
        getLocale: functions.getLocale,
        getAspect(image){
            let aspect = (image.image_height / image.image_width) * 100;
            return aspect + '%'

        }
    },
}

</script>

<style scoped>
a { color: black; }

time, button{
    display: block;
}

button {
    margin: var(--margin-green) 0 calc(var(--margin-green) + .125rem) 0; 
}

div > ul > li:not(:last-child) {
    margin-bottom:  calc(var(--margin-yellow) + .625rem + .21875rem );
}

div > ul > li.no-img:last-child button{
    margin-bottom: 0;
}

figcaption {
    margin-top: calc(var(--margin-green) / 2);
    font-size: var(--micro-fontsize);
    display: flex;
}

figure {
    overflow: hidden;
}

.placeholder{
    background-color: lightgrey;
    width: 100% !important;
    height: 0px !important;
    position: unset !important;
}

</style>
