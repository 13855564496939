<template>
<masonry v-if="sortevents" :cols="{default: 3, 1439: 2, 767: 1}" :gutter="10">
    <component v-for="(item, index) in sortevents" v-bind:is="getComponent(item.class)" :type="item.class" :tag="tagStatus(item)" :event="item" :key="index"></component>
</masonry>

<!--
<div class="masonry">
    <component v-for="(item, index) in events" v-bind:is="getComponent(item.class)" :type="item.class" :tag="tagStatus(item)" :event="item" :key="index"></component>
</div>
-->
</template>
<script>
import Element from '@/components/snippets/Element'
import Item from '@/components/snippets/Item'
import functions from '@/functions.js'
import api from '@/api'
import {store} from '@/centralStore'

export default {
    name: 'Home',
    data(){
        return{
            events: null,
            sortevents: null,
            store: store
        }
    },
    components: {
        Element,
        Item
    },
   methods: {
        getComponent(c){
            if (c == 'Event')
                return 'Element'
            else
                return 'Item'
        },

        tagStatus: function(e) {
            if (e.class == 'Event' && e.category != 'VE')
                return this.isItFuture(e.start_date)
            return true
        },
        isItFuture: functions.isItFuture,
        sortByDate: functions.sortByDate
    },
    mounted(){
        
        api.get('home/')
            .then(response => {
                this.events = this.sortByDate(response.data.events.filter(a => a.class == 'Event'), 'start_date', false)
                
                this.sortevents = this.sortByDate(response.data.events.filter(a => (a.category == 'AS' | a.category == 'VI') && this.isItFuture(a.start_date) == true))
                
                const iteratorVE = this.sortByDate(response.data.events.filter(a => a.category == 'VE')).values()
                for (const value of iteratorVE){
                    this.sortevents.push(value)
                }
                
                const iteratorVO = this.sortByDate(response.data.events.filter(a => (a.category == 'AS' | a.category == 'VI') && this.isItFuture(a.start_date) == false)).values()
                for (const value of iteratorVO){
                    this.sortevents.push(value)
                }

                const iteratorIT = this.sortByDate(response.data.events.filter(a => a.class == 'AnnualGift' || a.class == 'ShopItem' ), 'start_date', false).values()
                for (const value of iteratorIT){
                    this.sortevents.push(value)
                }
                
                this.store.events = this.events.filter(a => (a.category == 'AS' || a.category == 'VI') && this.isItFuture(a.start_date) == true )
                if (this.events.length < 1) this.store.startscreen = false
                
                const iterator = this.sortByDate(response.data.events.filter(a => a.class == 'AnnualGift' || a.class == 'ShopItem' ), 'start_date', false).values()
                for (const value of iterator){
                    this.events.push(value)
                }
            })

    }
}
</script>
<style scoped>
.mobile > main > div {
  margin-bottom: -0.125rem;
}

.masonry {
    columns: 1;
}
.masonry > div {
    break-inside: avoid;
}
</style>
