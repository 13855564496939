<template>
<div v-if="$route.name == 'agenda' ">
  <div class="column">
    <p v-if="res" v-html="getAttr(res.agenda, 'text')"></p>
  </div>
</div>
<div v-else-if="$route.name == 'team' ">
  <div v-if="res" class="column">
    <ul v-for="(role, index) in roles" :key="index">
      <li><p>{{ getAttr(role, 'role') }}:</p></li>
      <li v-for="(person, index) in getTeam(getAttr(role, 'role'), true)" :key="index"><p v-html="person.names"></p></li>
    </ul>
  </div>
</div>
<div v-else-if="$route.name == 'geschichte'" class="column-wrap adjust">
  <div v-if="res">
    <Slider :images="res.geschichte.images" :text="true"></Slider>
    <div v-if="res && mq.xlMinus">
      <p v-html="getAttr(res.geschichte, 'text')"></p>
    </div>
  </div>
  <div v-if="res && !mq.xlMinus">
    <p v-html="getAttr(res.geschichte, 'text')"></p></div>
  <div v-if="res">
    <ul v-for="(role, index) in formerRoles" :key="index">
      <li><p>{{ getAttr(role, 'role') }}:</p></li>
      <li v-for="(person, index) in getTeam(getAttr(role, 'role'))" :key="index"><p v-html="person.names"></p></li>
    </ul>
  </div>
</div>
<div v-else-if="$route.name == 'impressum'">
  <div v-if="getLocale() == 'en-US'" class="column">
  <p>
    Kunstverein Harburger Bahnhof von 1999 e.V.<br>
    im Bahnhof über Gleis 3/4<br>
    Hannoversche Straße 85<br>
    21079 Hamburg<br>
    Phone +49 40 76753896<br>
    Fax +49 40 76754785<br>
    E–Mail <a class="na" href="mailto:info@kvhbf.de">info@kvhbf.de</a><br>
    <br>
    Authorized board members: Julia Sökeland & Dr. Lutz Hoffmann (1st and 2nd Chairman /woman)<br>
    Responsible local court: Hamburg<br>
    Extract from the association register No.: VR 16416<br>
    Responsible according to art. 55 para. 2 RStV: Tobias Peper<br>
    <br>
    Concept and Design<br>
    JMMP – Julian Mader, Max Prediger<br>
    <a class="na" href="http://jmmp.eu" target="_blank">jmmp.eu</a><br>
    <br>
    Programming<br>
    Tilman Junghans, Dominic Osterried<br>
    </p>
    <h2>Liability For Links</h2>
    <p>
    The offer we provide contains links to external websites of third parties that we have no influence on. 
    For this reason, we disclaim responsibility for such third-party content. 
    It is in all cases the author or operator of the linked web pages that is responsible for the contents of these pages. 
    The linked pages were checked for possible legal infringements at the time the link was established. 
    No unlawful contents were recognizable at that point of time. The permanent subsequent control of the linked pages without a concrete indication of a violation of law is deemed unreasonable. 
    Should any violations of law become known to us, we will immediately take action and remove such links.<br>
    </p>
    <h2>Copyright</h2>
    <p>
    All rights reserved, in particular the right of reproduction, distribution and translation. 
    None of the illustrations may be reproduced or processed, duplicated or distributed using electronic systems without the permission of the respective artists.<br>
    <br>
    © Kunstverein Harburger Bahnhof, the artists, photographers and authors – 2022<br>
    </p>
    <h2>Supported by:</h2>
    <ul class="logos">
      <li><img src="~@/assets/logos/BKM_DTP.jpg"></li>
      <li><img src="~@/assets/logos/BKM_Neustart.jpg"></li>
      <li><img src="~@/assets/logos/DARV.jpg"></li>
    </ul>
  </div>
  <div v-else class="column">
  <p>
    Kunstverein Harburger Bahnhof von 1999 e.V.<br>
    im Bahnhof über Gleis 3/4<br>
    Hannoversche Straße 85<br>
    21079 Hamburg<br>
    Telefon +49 40 76753896<br>
    Telefax +49 40 76754785<br>
    E–Mail <a class="na" href="mailto:info@kvhbf.de">info@kvhbf.de</a><br>
    <br>
    Vertretungsberechtigter Vorstand: Julia Sökeland & Dr. Lutz Hoffmann (1. und 2. Vorsitzende)<br>
    Zuständiges Amtsgericht: Hamburg<br>
    Vereinsregisterauszug-Nr.: VR 16416<br>
    Verantwortlich i.S.d. § 55 Abs. 2 RStV: Tobias Peper<br>
    <br>
    Konzept und Gestaltung<br>
    JMMP – Julian Mader, Max Prediger<br>
    <a class="na" href="http://jmmp.eu" target="_blank">jmmp.eu</a><br>
    <br>
    Programmierung<br>
    Tilman Junghans, Dominic Osterried<br>
    </p>
    <h2>Haftungshinweis</h2>
    <p>
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. 
    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. 
    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. 
    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br>
    </p>
    <h2>Urheberrecht</h2>
    <p>
    Alle Rechte, insbesondere das Recht auf Vervielfältigung und Verbreitung sowie Übersetzung vorbehalten. 
    Keine der Abbildungen darf ohne Genehmigung der jeweiligen Künstler:innen reproduziert oder unter Verwendung elektronischer Systeme verarbeitet, 
    vervielfältigt oder verbreitet werden.<br>
    <br>
    © Kunstverein Harburger Bahnhof, die Künstler:innen, Fotograf:innen und Autor:innen – 2022<br>
  </p>
  <h2>Gefördert von:</h2>
      <ul class="logos">
        <li><img src="~@/assets/logos/BKM_DTP.jpg"></li>
        <li><img src="~@/assets/logos/BKM_Neustart.jpg"></li>
        <li><img src="~@/assets/logos/DARV.jpg"></li>
      </ul>
  </div>
</div>
<div v-else-if="$route.name == 'datenschutz'">
  <div v-if="getLocale() == 'en-US'" class="column">
  <p>
    Privacy Policy:<br>
    This website is being operated by Kunstverein Harburger Bahnhof, Hannoversche Str. 85, 21079 Hamburg, <a href="mailto:info@kvhbf.de" class="na">info@kvhbf.de</a>. In case you have questions concerning your data you can contact us as mentioned above.
    We want to ensure safe usage of our website for users. Personal data is treated in terms of privacy policy and data protection. Our website can be used without any commitment. Statements upon personal information, such as name, e-mail-address, etc. are on a voluntary basis. Personal information will not be shared without verification of the user.
    <br><br>
    Cookies:<br>
    Some of our web pages use cookies. Cookies do not harm your computer and do not contain any viruses. Cookies help make this website more user-friendly, efficient, and secure. Cookies are small text files that are stored on your computer and saved by your browser.
    Most of the cookies we use are so-called „session cookies.“ They are automatically deleted after your visit. Other cookies remain in your device’s memory until you delete them. These cookies make it possible to recognize your browser when you next visit the site.
    You can configure your browser to inform you about the use of cookies so that you can decide on a case-by-case basis whether to accept or reject a cookie. Alternatively, your browser can be configured to automatically accept cookies under certain conditions or to always reject them, or to automatically delete cookies when closing your browser. Disabling cookies may limit the functionality of this website.
    <br><br>
    Server Log Files:<br>
    The website provider automatically collects and stores information that your browser automatically transmits to us in „server log files“. These are:
    </p>
    <ul>
    <li><p><span></span> Browser type and browser version</p></li>
    <li><p><span></span> Operating system used</p></li>
    <li><p><span></span> Referrer URL</p></li>
    <li><p><span></span> Host name of the accessing computer</p></li>
    <li><p><span></span> Time of the server request</p></li>
    </ul>
    <p>
    These data will not be combined with data from other sources.
    <br><br>
    Newsletter<br>
    With our newsletter we inform you about exhibitions and events at Kunstverein Harburger Bahnhof. To subscribe to our newsletter you need to provide us with your e-mail-address. The shared personal information will be used for this purpose only. Because we use the service of another company for sending our newsletters, the data is led over a server of the company GetResponse, Arkońska 6, A3, 80-387 Gdańsk, Poland.
    The possibility of unsubscribing is given by a direct link in a newsletter mail or by contacting us via e-mail.
    Your personal information will be saved until you unsubscribe and deleted afterwards.
    <br><br>
    Youtube:<br>
    For the integration of video material, we use plugins from YouTube. YouTube, LLC, is based in 901 Cherry Ave., San Bruno, CA 94066, USA. By using a website with YouTube-Plugin a connection to the YouTube servers is built up. That is how YouTube gets to know which of our sites you visited. YouTube can profile your internet surfing activities if you are logged in with your YouTube account. You can stop this profiling by logging out. We use YouTube for a pleasant arrangement of our website, which is of legitimate interest following art. 6 sec. 1 lit. f DSGVO. Details of usage of personal information can be find in the privacy policy of YouTube: <a href="https://policies.google.com/privacy?hl=en" class="na">https://policies.google.com/privacy?hl=en</a>
    <br><br>
    Vimeo:<br>
    For the integration of video material, we use plugins from YouTube. Vimeo, LLC, is based in West 18th Street, White Plains, 10011 New York, USA. By using a website with Vimeo-Plugin a connection to the Vimeo servers is built up. That is how Vimeo gets to know which of our sites you visited. Vimeo can profile your internet surfing activities if you are logged in with your Vimeo account. You can stop this profiling by logging out. We use Vimeo for a pleasant arrangement of our website, which is of legitimate interest following art. 6 sec. 1 lit. f DSGVO. Details of usage of personal information can be find in the privacy policy of Vimeo: <a href="https://vimeo.com/privacy" class="na">https://vimeo.com/privacy</a>
    <br><br>
    Soundcloud:<br>
    For the integration of audio material, we use plugins from SoundCloud. SoundCloud Limited is based in 47-48 Berners Street, London W1T 3NF, UK. By using a website with SoundCloud-Plugin a connection to the SoundCloud servers is built up. That is how SoundCloud gets to know which of our sites you visited. SoundCloud can profile your internet surfing activities if you are logged in with your SoundCloud account. You can stop this profiling by logging out. We use SoundCloud for a pleasant arrangement of our website, which is of legitimate interest following art. 6 sec. 1 lit. f DSGVO. Details of usage of personal information can be find in the privacy policy of SoundCloud: <a href="https://soundcloud.com/pages/privacy" class="na">https://soundcloud.com/pages/privacy</a>
  </p>
  </div>
  <div v-else class="column">
  <p>
    Datenschutzerklärung:<br>
    Diese Webseite wird betrieben vom Kunstverein Harburger Bahnhof, Hannoversche Str. 85, 21079 Hamburg, <a href="mailto:info@kvhbf.de" class="na">info@kvhbf.de</a>. 
    Sollten Sie Fragen zu Ihren Daten haben erreichen Sie uns unter den angegebenen Kontaktdaten.
    Ihre personenbezogenen Daten werden vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung behandelt. Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. 
    Soweit auf unseren Seiten personenbezogene Daten (z.B. Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
    <br><br>
    Cookies:<br>
    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
    Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
    <br><br>
    Server-Log-Files:<br>
    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
    </p>
    <ul>
      <li><p><span></span> Browsertyp und Browserversion</p></li>
      <li><p><span></span> verwendetes Betriebssystem</p></li>
      <li><p><span></span> Referrer URL</p></li>
      <li><p><span></span> Hostname des zugreifenden Rechners</p></li>
      <li><p><span></span> Uhrzeit der Serveranfrage</p></li>
    </ul>
    <p>
    Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
    <br><br>
    Newsletter:<br>
    Mit unserem Newsletter informieren wir Sie über die Ausstellungen und Veranstaltungen des Kunstvereins Harburger Bahnhof. Für den Bezug unseres Newsletters ist die Angabe Ihrer E-Mail-Adresse notwendig. Die Verwendung der Daten erfolgt nur für ebendiesen Zweck. Da wir zum Versenden des Newsletters einen Anbieter konsultiert haben, erfolgt die Leitung der Daten über den Server unseres Anbieters GetResponse, Arkońska 6, A3, 80-387 Gdańsk, Polen.
    Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen über den unsubscribe-Link im Newsletter oder Kontaktaufnahme per E-Mail.
    Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters gelöscht.
    <br><br>
    Youtube:<br>
    Für die Integration und Darstellung von Videoinhalten nutzt unsere Website Plugins von YouTube. Anbieter des Videoportals ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Bei Aufruf einer Seite mit integriertem YouTube-Plugin wird eine Verbindung zu den Servern von YouTube hergestellt. YouTube erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben. YouTube kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuordnen, sollten Sie in Ihrem YouTube Konto eingeloggt sein. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden. Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter: <a href="https://policies.google.com/privacy?hl=de" class="na">https://policies.google.com/privacy?hl=de</a>
    <br><br>
    Vimeo:<br>
    Für die Integration und Darstellung von Videoinhalten nutzt unsere Website Plugins von Vimeo. Anbieter des Videoportals ist die Vimeo LLC, mit Sitz in West 18th Street, White Plains, 10011 New York, USA. Bei Aufruf einer Seite mit integriertem Vimeo-Plugin wird eine Verbindung zu den Servern von Vimeo hergestellt. Vimeo erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben. Vimeo kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuordnen, sollten Sie in Ihrem Vimeo Konto eingeloggt sein. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden. Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter: <a href="https://vimeo.com/privacy" class="na">https://vimeo.com/privacy</a>
    <br><br>
    Soundcloud:<br>
    Zum Abrufen von Audio-Dateien nutzt unsere Website Plugins von SondCloud. Das Unternehmen SoundCloud Limited des Musikstreaming-Dienstes hat seinen Sitz in 47-48 Berners Street, London W1T 3NF, UK. Bei Aufruf einer Seite mit integriertem SoundCloud-Plugin wird eine Verbindung zu den Servern von SoundCloud hergestellt. SoundCloud erfährt hierdurch, welche unserer Seiten Sie aufgerufen haben. SoundCloud kann Ihr Surfverhalten direkt Ihrem persönlichen Profil zuordnen, sollten Sie in Ihrem SoundCloud Konto eingeloggt sein. Durch vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden. Die Nutzung von SoundCloud erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von SoundCloud unter: <a href="https://soundcloud.com/pages/privacy" class="na">https://soundcloud.com/pages/privacy</a>
  </p>
  </div>
</div>
<div v-else>
  <div v-if="getLocale() == 'de-DE'" class="column">
    <p>
      Kunstverein Harburger Bahnhof von 1999 e.V.<br>
      im Bahnhof über Gleis 3/4<br>
      Hannoversche Straße 85<br>
      21079 Hamburg<br>
      Phone +49 40 76753896<br>
      Fax +49 40 76754785<br>
      E–Mail <a class="na" href="mailto:info@kvhbf.de">info@kvhbf.de</a><br>
      <br>
      Öffnungszeiten: <br>
      Während der Ausstellungen<br>
      Mittwoch – Sonntag, 14 – 18 Uhr<br>
      Die Vitrinen auf Gleis 3/4 sind rund um die Uhr zugänglich.<br>
      <br>
      <!-- Weihnachtspause: Der Kunstverein bleibt vom 20.12.2021 bis einschließlich 7.1.2022 geschlossen.<br>
      <br> -->
      Wir bitten Sie, von initiativen Einreichungen künstlerischer Arbeiten abzusehen und bitten um Verständnis, dass diese nicht zurückgeschickt werden können.<br>
    </p>
    <h2>Anfahrt</h2>
    <ul>
      <li><p><span></span> Linien S3 & S31 Richtung Harburg, Ausstieg Harburg. Der Kunstverein befindet sich in der Bahnhofshalle über Gleis 3/4.</p></li>
      <li><p><span></span> Auto: Autobahn A7, Ausfahrt Hamburg-Harburg, von dort über den Zubringer bis Harburg-Mitte der Beschilderung zum Bahnhof Harburg folgen.
      Es befinden sich Parkhäuser gegenüber und hinter dem Bahnhof.</p></li>
      <li><p><span></span> Bahn: ICE, IC, RE, ME, Haltestelle Hamburg-Harburg. Der Kunstverein befindet sich im Bahnhof über Gleis 3/4.</p></li>
    </ul>
    <h2>Vermietung</h2>
    <p>
      Die Räumlichkeiten des Kunstvereins Harburger Bahnhof können für Veranstaltungen, Filmdrehs, etc. gemietet werden.<br>
      Bei Interesse stehen wir Ihnen gerne persönlich für Ihre Rückfragen zur Verfügung.
    </p>
  </div>
  <div v-else class="column">
    <p>
      Kunstverein Harburger Bahnhof von 1999 e.V.<br>
      im Bahnhof über Gleis 3/4<br>
      Hannoversche Straße 85<br>
      21079 Hamburg<br>
      Telefon +49 40 76753896<br>
      Telefax +49 40 76754785<br>
      E–Mail <a class="na" href="mailto:info@kvhbf.de">info@kvhbf.de</a><br>
      <br>
      Opening hours:<br>
      During exhibitions<br>
      Wed – Sun 2 – 6 pm<br>
      The showcases on platform 3/4 are accessible all day and night.<br>
      <br>
      <!-- Christmas break: Der Kunstverein bleibt vom 20.12.2021 bis einschließlich 7.1.2022 geschlossen.<br>
      <br> -->
      We kindly ask you to refrain from initiative submissions of artworks and ask for your understanding that these cannot be sent back.<br>
      </p>
      <h2>Directions</h2>
      <ul>
      <li><p><span></span> By public transport: Take S-Bahn S3 or S31 in the direction of Harburg, exit Harburg. The Kunstverein is located in the train station above platform 3/4.</p></li>
      <li><p><span></span> By car: Highway A7, exit Hamburg-Harburg. Via the connection road to Harburg-Mitte, follow signs to Harburg train station. There are parking garages opposite and behind the train station.</p></li>
      <li><p><span></span> By train: ICE, IC, RE, ME, exit train station Hamburg-Harburg. The Kunstverein is located in the train station above platform 3/4.</p></li>
      </ul>
      <h2>Renting</h2>
      <p>
      The space of the Kunstverein Harburger Bahnhof can be rented for events, film shoots, etc.<br>
      If you are interested, we would be happy to answer your questions personally.
    </p>
  </div>
  </div>
</template>

<script>
import api from '@/api'
import functions from '@/functions.js'
import Slider from '@/components/snippets/Slider'
export default {
  inject: ["mq"],
  components: {
    Slider
  },
  data(){
      return {
          res: null,
          roles: [],
          formerRoles: []
      }
  },
  methods :{
      getTeam(role, which){
        const wichTeam = (which) ? this.res.team : this.res.geschichte.team
        
        if (this.getLocale() == "en-US")
          return wichTeam.filter(a => a.role_en == role)
        else
          return wichTeam.filter(a => a.role_de == role)
      },
      getAttr: functions.getAttr,
      getLocale: functions.getLocale
  },
  mounted(){
      api.get('administration/')
          .then(response => {
              this.res = response.data.data
              this.roles = [...new Map(this.res.team.map((a) => [a['role_de'], a])).values()];
              this.formerRoles = [...new Map(this.res.geschichte.team.map((a) => [a['role_de'], a])).values()];
          })

  }
}

</script>
<style scoped>
.desktop .column { 
  width: 50%;
}

.desktop.xxl .column { 
  width: 33.33%;
}

.column-wrap > div > ul:not(:last-of-type), .column > ul:not(:last-of-type){
  margin-bottom: calc(var(--margin-yellow) - .1875rem);
}

.desktop .column-wrap{
    display: flex;
}

.desktop .column-wrap > *{
    width: 50%;
}

.desktop .column-wrap > *:nth-child(even){
    padding-left: .3125rem;
}

.desktop .column-wrap > *:nth-child(odd){
    padding-right: .3125rem;
}

.desktop.xxl .column-wrap > *{
    width: 33.33%;
    padding: 0 .625rem 0 0;
}

#slider{margin-top: 0;}

.adjust {
  margin-top: .25rem;
}

h2 { 
  margin: var(--margin-pink) 0;
}

ul > li > p {
  display: inline;
  align-items: baseline;
}

ul > li > p > span::before{
    content: '';
    border: .09375rem solid var(--border);
    width: calc( var(--base-fontsize) - var(--base-fontsize) / 4 );
    height: calc( var(--base-fontsize) - var(--base-fontsize) / 4 );
    border-radius: calc( var(--base-fontsize) - var(--base-fontsize) / 4 );
    flex-shrink: 0;
    background: var(--text);
    display: inline-flex;
}

.mobile > main > div {
  margin-bottom: -0.125rem;
}

#logos {
    margin-top: var(--margin-pink);
}

#logos > li {
    margin-bottom: var(--margin-yellow);
}

#logos p{
    font-size: var(--micro-fontsize);
    margin-bottom: calc( var(--margin-green) - .0625rem );
}

.logos{
    display: flex;
    flex-wrap: wrap;
    padding-top: .125rem;
}

.logos li {
  height: 2.75rem;
  margin-bottom: .625rem;
  margin-right: 1.25rem;
}

.logos li img{
    width: auto;
    height: 100%;
}

</style>