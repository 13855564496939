<template>
<div id="overlay">
    <div v-if="!success">
    <header>
        <h1>{{ $t("title.mitgliedsantrag") }}</h1>
        <button class="x" @click="toggleMember"><h4>&#xF0011;</h4></button>
    </header>
    <form v-on:submit.prevent="submitForm">
        <div>
            <label class="inline">
                <input type="radio" name="optmember" value="Persönlich" v-model="form.membership" required checked><h3>{{$t('member.self')}}</h3>
            </label>
            <label class="inline">
                <input type="radio" name="optmember" value="Geschenk" v-model="form.membership"><h3>{{$t('member.gift')}}</h3>
            </label>
        </div>
        <div class="gap">
            <label class="inline">
                <input type="radio" name="optfee" value="50" v-model="form.membership_fee" required checked><h3>{{$t('member.voll')}}</h3>
            </label>
            <label class="inline">
                <input type="radio" name="optfee" value="25" v-model="form.membership_fee"><h3>{{$t('member.ermaessigt')}}</h3>
            </label>
            <label class="inline">
                <input type="radio" name="optfee" value="60" v-model="form.membership_fee"><h3>{{$t('member.familie')}}</h3>
            </label>
            <label class="inline">
                <input type="radio" name="optfee" value="Individual" v-model="form.membership_fee"><h3>{{$t('member.foerder')}}</h3>
            </label>
            <input v-if="form.membership_fee == 'Individual'" v-model="form.membership_fee_individual" type="number" min="300" placeholder="Betrag (ab 300 EUR)" required />
        </div>
        <input type="text" v-model="form.last_name" :placeholder="$t('order.name')" required>
        <input type="text" v-model="form.first_name" :placeholder="$t('order.vorname')" required>
        <input type="text" v-model="form.company" :placeholder="$t('order.zusatz')">
        <input type="text" v-model="form.street" :placeholder="$t('order.strasse')" required>
        <input type="text" v-model="form.zip" :placeholder="$t('order.postleitzahl')" required>
        <input type="text" v-model="form.city" :placeholder="$t('order.ort')" required >
        <input type="text" v-model="form.country" :placeholder="$t('order.land')" required>
        <input type="email" v-model="form.email" :placeholder="$t('order.email')" required>
        <input type="tel" pattern="^[0-9-+\s()]*$" v-model="form.phone" :placeholder="$t('order.telefon')" required>
        <label class="inline huge">
            <input type="checkbox" name="optinvoice" v-model="form.invoice"><h3>{{$t('member.rechnungsadresse')}}</h3>
        </label>
        <div v-if="form.invoice">
            <input type="text" v-model="form.invoice_data.last_name" :placeholder="$t('order.name')" required>
            <input type="text" v-model="form.invoice_data.first_name" :placeholder="$t('order.vorname')" required>
            <input type="text" v-model="form.invoice_data.company" :placeholder="$t('order.zusatz')">
            <input type="text" v-model="form.invoice_data.street" :placeholder="$t('order.strasse')" required>
            <input type="text" v-model="form.invoice_data.zip" :placeholder="$t('order.postleitzahl')" required>
            <input type="text" v-model="form.invoice_data.city" :placeholder="$t('order.ort')" required>
            <input type="text" v-model="form.invoice_data.country" :placeholder="$t('order.land')" required>
            <input type="email" v-model="form.invoice_data.email" :placeholder="$t('order.email')" required>
            <input type="tel" pattern="^[0-9-+\s()]*$" v-model="form.invoice_data.phone" :placeholder="$t('order.telefon')" required>
        </div>
        <ul>
            <li v-for="(item, index) in $t('member.member_list').split('*list*')" :key="index"><p class="st"><span></span><small>{{ item }}</small></p></li>
        </ul>
        <label class="inline huge c">
            <input type="checkbox" name="optsepa" v-model="form.sepa"><h3>{{$t('member.sepa')}}</h3>
        </label>
        <div v-if="form.sepa" class="sepa">
            <input type="text" v-model="form.sepa_data.last_name" :placeholder="$t('order.name')" required>
            <input type="text" v-model="form.sepa_data.first_name" :placeholder="$t('order.vorname')" required>
            <input type="text" v-model="form.sepa_data.company" :placeholder="$t('order.zusatz')">
            <input type="text" v-model="form.sepa_data.street" :placeholder="$t('order.strasse')" required>
            <input type="text" v-model="form.sepa_data.zip" :placeholder="$t('order.postleitzahl')" required>
            <input type="text" v-model="form.sepa_data.city" :placeholder="$t('order.ort')" required>
            <input type="text" v-model="form.sepa_data.country" :placeholder="$t('order.land')" required>
            <input type="text" v-model="form.sepa_data.iban" placeholder="IBAN" required>
            <input type="text" v-model="form.sepa_data.bic" placeholder="BIC">
            <input type="text" v-model="form.sepa_data.bank" placeholder="Bank" required>
        </div>
        <ul v-if="form.sepa" class="c">
            <li v-for="(item, index) in $t('member.sepa_list').split('*list*')" :key="index"><p class="st"><small>{{ item }}</small></p></li>
        </ul>
        <button @click="pseudoval($event)" type="submit"><h3>{{$t('title.mitgliedwerden')}}</h3></button>
    </form>
    <h4 class="error">{{ $t('order.error') }}</h4>
    </div>
    <div v-else>
        <header>
            <h1>{{$t('text.danke_a')}}</h1>
            <button @click="toggleMember">&#x2715;</button>
        </header>
        <h1>{{$t('text.danke_b')}}</h1>
    </div>
</div>
</template>



<script>
import api from '@/api'
import {store} from '@/centralStore'
import functions from '@/functions.js'

export default {
    name: 'MemberForm',
    data(){
        return{
            store: store,
            success: false,
            form: {
                membership: 'Persönlich',
                membership_fee: '50',
                membership_fee_individual: '',
                first_name: '',
                last_name: '',
                company: '',
                street: '',
                zip: '',
                city: '',
                country: '',
                phone: '',
                email: '',
                invoice: false,
                invoice_data: {
                    first_name: '',
                    last_name: '',
                    company: '',
                    street: '',
                    zip: '',
                    city: '',
                    country: '',
                    phone: '',
                    email: ''
                },
                sepa: false,
                sepa_data: {
                    first_name: '',
                    last_name: '',
                    company: '',
                    street: '',
                    zip: '',
                    city: '',
                    country: '',
                    iban: '',
                    bic: '',
                    bank: ''
                },

            }
        }
    },
    methods: {
        toggleMember(){
            this.store.memberVisible = !this.store.memberVisible
        },
        submitForm(){
            api.post('submit-membership/', this.form)
                .then(response => {
                    console.log(response)
                    if ( response.status == '200' )
                        this.success = true
                })
        },
        pseudoval(element){
            element.target.parentElement.classList.add('firsttry')
        },
        getCat: functions.getCat,
        getAttr: functions.getAttr,
        getLocale: functions.getLocale
    },
    
    mounted () {
        document.body.classList.add('no-scroll')
    },
    unmounted () {
        document.body.classList.remove('no-scroll')
    }
}



</script>


<style scoped>
#overlay{
    position: fixed; 
    overflow-y: scroll;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0,0,0,0.06);
}

#overlay > div {
    width: calc(100% - (var(--margin-green) + .125rem) * 2);
    border-radius: .625rem;
    background: var(--accent);
    color: var(--bg);
    padding: calc(var(--margin-green) + .125rem);
    min-height: calc(100% - (var(--margin-green) + .125rem) * 2);
    margin: calc(var(--margin-green) + .125rem);
    /*-webkit-box-shadow: 0px 0px 10px 0px #000029; 
    box-shadow: 0px 0px 10px 0px #000029;*/
}

.desktop #overlay > div {
    width: 33.333%;
}

.desktop.xxl #overlay > div {
    width: 25%;
}

label, input[type="radio"] {
    cursor: pointer;
}

input, select, textarea {
    width: 100%;
    margin-bottom: calc(var(--margin-green) - .1875rem);
    color: var(--text);
}

*::placeholder {
    color: var(--text);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button {
    width: 100%;
    background: var(--accent) !important;
    color: var(--bg) !important;
    border-color: var(--bg);
}

button:hover {
    background: var(--bg) !important;
    color: var(--accent) !important;
}

.inline {
    display: flex;
    margin-top: calc(var(--margin-green) - .2725rem);
}

.inline.huge {
    margin-top: calc(var(--margin-green) + .75rem);
    margin-bottom: 1.5rem;
}

.inline.huge.c {
    margin-top: calc(var(--margin-green) + .875rem);
    margin-bottom: 1.625rem;
}

.inline + div, .inline + button {
    margin-top: 0;
}

.gap {
    margin-bottom: calc(var(--margin-pink) + .25rem);
}

.gap input{
    margin-bottom: 0;
    margin-top: calc(var(--margin-green) - .125rem);
}

input[type="radio"], input[type="checkbox"]{
    flex-shrink: 0;
    height: var(--small-fontsize);
    width: var(--small-fontsize);
    padding: 0;
    margin: 0;
    margin-top: calc( var(--small-fontsize) / 8);
    margin-right: var(--margin-green);
}

input {
    background: var(--accent);
    color: var(--bg);
    border-color: var(--bg);
}

input::placeholder {
    color: var(--bg);
    opacity: 1;
}

input[type="radio"]:checked, input[type="checkbox"]:checked{
    background: var(--bg);
}

ul {
    margin: calc(var(--margin-yellow) + .5rem) 0;
}

ul > li {
    line-height: 0;
}

p > span::before{
    content: '';
    border: .125rem solid var(--bg);
    width: calc( var(--micro-fontsize) - var(--micro-fontsize) / 4 );
    height: calc( var(--micro-fontsize) - var(--micro-fontsize) / 4 );
    border-radius: calc( var(--micro-fontsize) - var(--micro-fontsize) / 4 );
    background: var(--bg);
    flex-shrink: 0;
}


p > span { 
    display: inline-flex;
    align-items: baseline;
}

header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--margin-pink) - .375rem);
}

header > h1 {
    margin-top: -.1825rem;
    font-variation-settings: "wght" 90, "hght" 536, "prtn" 0;
    font-feature-settings: "ss01" 0, "ss02" 0, "ss04" 0;
}

header > button {
    padding: 0 .35rem;
    color: var(--text);
    background: var(--bg);
    width: var(--button);
    flex-shrink: 0;
    padding-bottom: 0.0625rem;
}

button[type="submit"] > h3 {
    pointer-events: none;
}

form.firsttry input:invalid, form.firsttry select:invalid {
    border-color: var(--text);
}

form.firsttry input:invalid::placeholder, form.firsttry select:invalid, form.firsttry select:invalid + label {
    color: var(--text);
}

form + h4 {
    display: none;
    margin-top: var(--margin-green);
}

form.firsttry:invalid + h4 {
    display: block;
    color: var(--text);
}

.sepa > input:last-of-type {
    margin-bottom: 0;
}

ul.c {
    margin: 1.6875rem 0;
}

</style>
