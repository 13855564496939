<template>
 <div id="slider" v-bind:class="(gift||shop)?'item':''" @touchstart="touchStart">
    <ul>
        <li v-for="(image, index) in images" :key="index" :data-index="index">
            <figure> 
                <div v-on="mq.lgPlus ? { click: () => toggleFullscreen(images, index) } : {}">
                    <img v-if="text" :src="image.image">
                    <!-- <v-lazy-image  v-else :src-placeholder="image.thumbnail_tiny" :src="(mq.mdPlus) ? image.thumbnail_large : image.thumbnail_small" /> -->
                    <div v-if="!imageLoaded" id="placeholder" :style="{paddingBottom: getAspect(image)}"></div>
                    <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? image.thumbnail_large : image.thumbnail_small" alt="">
                    <div v-if="images.length > 1" v-on:click="slideLeft">
                        <button v-if="images.length > 1" v-on:click="slideLeft"><h4>&#x2190;</h4></button>
                    </div>
                    <button disabled class="fullscreen"><h4>&#xF0015;</h4></button>
                    <div v-if="images.length > 1" v-on:click="slideRight">
                        <button v-if="images.length > 1" v-on:click="slideRight"><h4>&#x2192;</h4></button>
                    </div>
                </div>
                <figcaption v-if="images.length > 1 || image.caption_de || image.caption_en"><span v-if="images.length > 1" class="counter">{{index+1}}/{{images.length}}</span><span>{{ getAttr(image, 'caption') }}</span></figcaption>
                <figcaption v-else></figcaption>
            </figure>
        </li>
    </ul>
    <form v-if="unique" id="unique">
        <p><small>{{ $t('text.unikat') }}</small></p>
        <div>
        <label v-for="(image, index) in images" :key="index" :data-available="!image.available" :data-index="index">
            <input @click="autoCheck(null, $event)" type="radio" name="uniqueRadio" :disabled="false">
            <img :src="image.thumbnail_small">
        </label>
        </div>
    </form>
    <div v-if="gift || shop">
        <button v-if="button || !element.available" disabled><h3>{{ $t('title.vergriffen') }}</h3></button>
        <button v-else @click="toggleOrder" class="active">
            <h3>{{ $t('title.bestellen') }}</h3>
        </button>
        <p v-if="gift" class="st"><small>{{ $t('text.besichtigung')}} <a href="mailto:info@kvhbf.de">info@kvhbf.de</a>.</small></p>
    </div>
</div>
</template>

<script>
import functions from '@/functions.js'
import {store} from '@/centralStore'
//import VLazyImage from 'v-lazy-image'

export default{
    inject: ["mq"],
    name: 'Slider',
    props: ['images', 'unique', 'gift','shop', 'element', 'text'],
    components: {
        //VLazyImage
    },
    data(){
        return {
            selected: false,
            button: null,
            orderElement: null,
            store: store,
            uni: this.unique,
            imageLoaded: false,
        }
    },
    methods: {
        getAttr: functions.getAttr,
        slideLeft: function(){
            const s = document.querySelector('#slider ul')
            s.prepend(s.lastElementChild)
            if (this.gift)
                this.autoCheck(s.firstElementChild)
            
        },
        slideRight: function(){
            const s = document.querySelector('#slider ul')
            s.append(s.firstElementChild)
            if (this.gift)
                this.autoCheck(s.firstElementChild)
        },
        touchStart (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) {
            return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd (touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) {
            return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd) {
            this.slideLeft();
            } else if (posXStart > posXEnd) {
            this.slideRight();
            }
        },
        autoCheck: function(element, event){
            if(event){
                const s = document.querySelector('#slider ul'),
                    nodeList = [...s.querySelectorAll('li')],
                    nodeArray = nodeList.splice(0, nodeList.indexOf(s.querySelector('li[data-index="'+event.target.parentElement.dataset.index+'"]')))
                for ( const singleNode of nodeArray){
                    s.append(singleNode)
                }
                this.autoCheck(event.target.parentElement)
                this.button = (/true/i).test(event.target.parentElement.dataset.available)
            }
            
            if(element){
                const s = document.querySelector('label[data-index="'+element.dataset.index+'"] [type=radio]')
                this.button = s.disabled
                s.checked = true
            }
            

        },
        toggleFullscreen(element, i){
            this.store.fullscreen = !this.store.fullscreen
            this.store.active = i
            this.store.element = element
        },
        toggleOrder(){
            this.store.orderVisible = !this.store.orderVisible
            const s =  document.querySelector('#slider > ul > li:first-of-type').dataset.index
            const plainObject = { ...this.element };
            this.orderElement = {
                pk: s,
                artists: plainObject.artists,
                title_de: plainObject.title_de,
                title_en: plainObject.title_en,
                slug: plainObject.slug,
                price: plainObject.price,
                images: [plainObject.images[s]],
                gift: this.gift,
                unique: this.uni,
                maxUnique: plainObject.images.length
            }
            this.store.element = this.orderElement
        },
        getAspect(image){
            let aspect = (image.image_height / image.image_width) * 100;
            return aspect + '%'

        }

    },
     mounted() {
        
        this.$nextTick(function () {
            if(this.gift && this.unique){
                
                const firstToCheck = document.querySelector('[type=radio]:not(:disabled):first-of-type')
                if (firstToCheck)
                    firstToCheck.checked = true
                this.button = (/true/i).test(firstToCheck.parentElement.dataset.available)
            }
        });
    }
}

</script>

<style scoped>
#slider {
    margin-top: var(--margin-green);
    margin-bottom: calc( var(--margin-pink) - .0625rem );
}

#slider figure > div {
    position: relative;
    font-size: 0;
}

#slider figure > div > div {
    position: absolute;
    top: 0;
    height: 100%;
    width: 30%;
    cursor: pointer;
}

.mobile #slider figure > div > div > button {
    pointer-events: none;
}

@media (hover: hover) {
    #slider figure > div > div:hover button {
        color: var(--bg);
        background: var(--text);
    }
}

#slider figure > div button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 1.5625rem;
    height: 1.565rem;
    border-radius: 1.565rem;
    display: none;
    padding: 0;
    padding-bottom: .0625rem;
}

.mobile #slider figure > div button:not(.fullscreen) {
    display: block;
}

.desktop #slider figure > div {
    cursor: pointer;
}

.desktop #slider figure > div:hover > button.fullscreen {
    color: var(--bg);
    background: var(--text);
}

.desktop #slider figure > div > button.fullscreen {
    display: block;
    pointer-events: none;
}


#slider figure > div > div:first-of-type > button{
    left: calc(var(--margin-green) + .25rem );
}


#slider figure > div > div:last-of-type > button {
    right: calc(var(--margin-green) + .25rem );
}

#slider figure > div > div:first-of-type{
    left: 0;
}

#slider figure > div > div:last-of-type {
    right: 0;
}


#slider figure > div > button.fullscreen {
    right: calc(var(--margin-green) + .1875rem );
    top: calc(var(--heading-fontsize) + .1875rem);
}

#slider figure > div > button {
    margin: 0;
}

#slider > ul:first-of-type > li {
    display: none;
}

#slider > ul:first-of-type > li:first-of-type {
    display: block;
}

#slider button.active {
    color: var(--bg);
    background: var(--text);
}

#slider ul button{
    border: 0;
}

#slider button{
    margin: var(--margin-green) 0;
    margin-bottom: calc(var(--margin-green) - .0625rem);
}

#slider button:last-child{
    margin-bottom: var(--margin-green);
}

.item figcaption {
    flex-direction: column;
}

.item figcaption span + span {
    margin-top: var(--margin-orange);
}

figcaption span:first-of-type{
    margin-right: var(--margin-green);
}

#unique{
    margin-top: var(--margin-yellow);
    margin-bottom: .125rem;
}

#unique div{
    display: flex;
    flex-wrap: wrap;
    gap: 0.3125rem;
}

#slider p {
    font-size: var(--micro-fontsize);
    margin-bottom: var(--margin-orange);
}

#unique div label {
    height: 4.5rem;
}

.desktop #unique div label {
    height: 4.5rem;
}

.desktop.xxl #unique div label {
    height: 4.5rem;
}

[type=radio] {
  position: absolute;
  opacity: 0;
}

[type=radio]+img {
  cursor: pointer;
  opacity: .5;
  height: 100%;
  width: auto;
}

#placeholder{
    background-color: lightgrey;
    width: 100% !important;
    height: 0px !important;
    position: unset !important;
}

div > label:hover img {
  opacity: 1;
}

[type=radio]:disabled+img {
  cursor: not-allowed;
}

[type=radio]:checked + img {
  opacity: 1;
}

figure{
    overflow: hidden;
}

</style>
