<template>
<div class="column">
    <Header :results="results"></Header>
    <div v-if="results">
        <ul v-if="results.length > 0">
            <ElementShort v-for="result in results" :event="result" :key="result.slug" :tag="tagStatus(result)"></ElementShort>
        </ul>
        <h2 v-else class="gap">{{$t('text.search')}}</h2>
    </div>
</div>
</template>


<script>
import {store} from '@/centralStore'
import ElementShort from '@/components/snippets/ElementShort'
import api from '@/api'
import Header from '@/components/snippets/Header'
import functions from '@/functions.js'

export default {
    inject: ["mq"],
    components: {
        ElementShort,
        Header,
     },
    data(){
        return {
            store: store,
            results: null,
        }
    },
    methods : {
        getResults(){
            if (typeof this.$route.params.searchQuery != 'undefined'){
                api.get('search/' + this.$route.params.searchQuery)
                    .then(response => this.results = response.data.results)
            }
        },
        tagStatus: function(e) {
            if (e.class == 'Event' && e.category != 'VE')
                return this.isItFuture(e.start_date)
            return true
        },
        isItFuture: functions.isItFuture,
        sortByDate: functions.sortByDate
    },

    watch: { 
        '$route.params.searchQuery': {
            handler: function() {
                this.getResults()
            },
            deep: true,
            immediate: true
        }
    }
}


</script>

<style scoped>
ul, h2.gap {
    margin-top: var(--margin-pink);
}

.spinner {
  height: var(--button);
  width: auto;
  animation: spinner infinite 1s;
  margin-top: var(--margin-pink);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
