<template>
<div>
    <router-link :to="{ name: 'detail', params: { slug: event.slug  } }">
        <h2><time>{{ getDate(event.start_date, event.end_date) }}<span v-if="event.start_time && event.category == 'VE' ">, {{ $d(splitDate(event.start_date, event.start_time), 'time', getLocale()) }}
        {{ $t('title.uhr')}}</span></time></h2>
        <h2 v-if="event.group_show"><em>{{ getAttr(event, 'title') }}</em></h2>
        <ul v-if="event.display_artists">
            <li v-for='artist in event.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2 v-if="!event.group_show"><em>{{ getAttr(event, 'title') }}</em></h2>
        <button v-if="tag" disabled :class="{ 'no-img' : (event.group_show) ? !event.images : !event.thumbnail_large }"><h3>{{ getSubCat(event) }}</h3></button>
        <button v-else disabled :class="{ 'no-img' : (event.group_show) ? !event.images : !event.thumbnail_large }"><h3>{{$t("cat.vorschau")}}</h3></button>
        <figure v-if="event.thumbnail_large && !event.group_show">
            <!-- <v-lazy-image :src-placeholder="event.thumbnail_tiny" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" /> -->
            <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(event)}"></div>
            <img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? event.thumbnail_large : event.thumbnail_small" alt="">
            <figcaption v-if="event.text_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.text_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <div v-else class="spacer"></div>
        </figure>
        <div v-else-if="event.group_show && event.images">
            <ul class="groupshow">
                <li v-for="(item, index) in event.images.slice().reverse()" :key="index">
                    <figure>
                        <!-- <div><v-lazy-image :src-placeholder="item.thumbnail_tiny" :src="(mq.mdPlus) ? item.thumbnail_large : item.thumbnail_small" /></div> -->
                        <div v-if="!imageLoaded" class="placeholder" :style="{paddingBottom: getAspect(item)}"></div>
                        <div><img v-show="imageLoaded" @load="imageLoaded = true" :src="(mq.mdPlus) ? item.thumbnail_large : item.thumbnail_small" alt=""></div>
                    </figure>
                </li>
            </ul>
            <figcaption v-if="event.text_de != '' && getLocale() == 'de-DE'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <figcaption v-else-if="event.text_en != '' && getLocale() == 'en-US'" v-html="getAttr(event, 'text')" v-snip="{ lines: 2, mode: 'js', midWord: false, ellipsis: ' .\u200A.\u200A. ' }"></figcaption>
            <div v-else class="spacer"></div>
        </div>

    </router-link>
</div>
</template>

<script>
import functions from '@/functions.js'
//import VLazyImage from 'v-lazy-image'
import {store} from '@/centralStore'

export default{
    inject: ["mq"],
    name: 'Element',
    props: ['event', 'tag'],
    subCat: false,
    components: {
        //VLazyImage
    },
    data(){
        return {
            timeout: [],
            store: store,
            imageLoaded: false
        }
    },
    methods: {
        getSubCat: functions.getSubCat,
        getLocale: functions.getLocale,
        getAttr: functions.getAttr,
        getDate: functions.getDate,
        getCat: functions.getCat,
        splitDate: functions.splitDate,
        getAspect(image){
            let aspect = (image.image_height / image.image_width) * 100;
            return aspect + '%'
        },
        setTimeOutGroupshow() {
            const groupshows = document.querySelectorAll('ul.groupshow')
            groupshows.forEach((element, index) => {
                this.timeout[index] = setInterval(function () {
                    const s = element
                    s.lastElementChild.classList.add('fade')
                    setTimeout( () => {
                        s.prepend(s.lastElementChild)
                        s.firstElementChild.classList.remove('fade')
                    }, 2000)
                    //s.append(s.firstElementChild)
                }, 5000);
            });
        }
    },
    beforeUnmount() {
        this.timeout.forEach(clearInterval)
        this.store.timeout = false
    },
    mounted(){
        if (!this.store.timeout){
            this.setTimeOutGroupshow()
            this.store.timeout = !this.store.timeout
        }
    }
}

</script>

<style scoped>
div:not(:last-of-type) > a { 
    margin-bottom: calc(var(--margin-yellow) + .625rem);
}

div > a {
    display: block;
    color: black;
}

button { 
    margin: var(--margin-green) 0 calc(var(--margin-green) + .125rem) 0; 
}

button.no-img{
    margin-bottom: .1875rem;
}

figure{
    overflow: hidden;
}

div.spacer{
    margin-bottom: .1875rem;
}

ul.groupshow > li {
    display: none;
}

ul.groupshow > li:nth-last-of-type(-n+2) {
    display: block;
}

/* */

ul.groupshow {
    width: 100%;
    height: calc((100vw - var(--frameh) * 2) / 3 * 2 );
    overflow: hidden;
    position: relative;
}

ul.groupshow > li {
    position: absolute;
    top: 0;
    left: 0;
}

ul.groupshow > li.fade {
    opacity: 0;
    transition: 1s opacity;
}

.mobile.md ul.groupshow {
    height: calc((50vw - var(--frame) * 2) / 3 * 2 );
}

.desktop ul.groupshow {
    height: calc((33.33vw - var(--frame) * 2) / 3 * 2 );
}

.desktop.xxl ul.groupshow {
    height: calc((25vw - var(--frame) * 2) / 3 * 2 );
}

/*
ul.groupshow > li > figure > div:not(.spacer) {
    width: 100%;
    height: calc((100vw - var(--frameh) * 2) / 3 * 2 );
    overflow: hidden;
}

.mobile.md ul.groupshow > li > figure > div:not(.spacer) {
    height: calc((50vw - var(--frame) * 2) / 3 * 2 );
}

.desktop ul.groupshow > li > figure > div:not(.spacer) {
    height: calc((33.33vw - var(--frame) * 2) / 3 * 2 );
}

.desktop.xxl ul.groupshow > li > figure > div:not(.spacer) {
    height: calc((25vw - var(--frame) * 2) / 3 * 2 );
}

*/

.placeholder{
    background-color: lightgrey;
    width: 100% !important;
    height: 0px !important;
    position: unset !important;
}


</style>

