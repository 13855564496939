import { reactive  } from 'vue'

export const store = reactive({
        menuVisible: false,
        footerVisible: true,
        orderVisible: false,
        memberVisible: false,
        fullscreen: false,
        startscreen: true,
        element: null,
        tinkerRoute: '/',
        lastRoute: '/',
        active: null,
        search: '',
        timeout: false,
        video: false,
        events: null
})
