<template>
    <div v-if="element" id="eventDetail">
        <div>
            <h2><time>{{ getDate(element.start_date, element.end_date) }}<span v-if="element.start_time && element.category == 'VE' ">, {{ $d(splitDate(element.start_date, element.start_time), 'time', getLocale()) }}
        {{ $t('title.uhr')}}</span></time></h2>
            <h2 v-if="element.group_show"><em>{{ getAttr(element, 'title') }}</em></h2>
            <ul v-if="element.display_artists">
                <li v-for='artist in element.artists' :key='artist'><h2>{{artist}}</h2></li>
            </ul>
            <h2 v-if="!element.group_show"><em>{{ getAttr(element, 'title') }}</em></h2>
            <Slider :images="element.images"></Slider>
            <div id="info" v-if="programs.length > 0 || element.info_text_de != ''">
                <ul id="program" v-if="programs.length > 0">
                    <li><h4>{{ $t('title.ausstellungsprogramm')}}</h4></li>
                    <li v-for="(text, index) in texts" :key="index">
                        <p><small><time class="notime"></time>{{ getAttr(text, 'text') }}</small></p>
                    </li>
                    <li v-for="(program, index) in programs" :key="index">
                        <p><small>
                            <time>{{ $d(program.date, 'short', getLocale()) }}</time>
                            <time v-if="program.time">{{ $d(splitDate(program.date, program.time), 'time', getLocale()) }}{{(getLocale() == 'de-DE')?' Uhr':''}}</time>
                            <span v-html="getAttr(program, 'text')"></span>
                        </small></p>
                    </li>
                </ul>
                <h4 v-if="element.info_text_de != ''" v-html="getAttr(element, 'info_text' )"></h4>
            </div>
        </div>
        <div>
            <p class="text" v-html="getAttr(element, 'text')"></p>
            <div v-if="element.media.length > 0 && mq.xlMinus && mq.lgPlus" id="video-block">
                <div v-for="(video, index) in element.media" v-on="mq.lgPlus && video.embed_type != 'SC' ? { click: () => toggleFullscreen(video, index) } : {}" :key="index" class="video">
                    <div v-if="video.embed_type != 'SC'">
                    <img :src="video.thumbnail" />
                    <button><h3>Play</h3></button>
                    </div>
                    <div v-else>
                        <Video :element="video" :key="index"></Video>
                    </div>
                    <h4 v-if="video.caption_de && video.embed_type != 'SC'">{{ getAttr(video, 'caption') }}</h4>
                </div>
            </div>
            <div v-else-if="element.media.length > 0 && mq.lgMinus" id="video-block">
                <Video v-for="(video, index) in element.media" :element="video" :key="index"></Video>
            </div> 
            <ul v-if="element.logo_blocks.length > 0 && mq.lgPlus" id="logos">
                    <li v-for="(block, index) in element.logo_blocks" :key="index">
                        <p class="st">{{ getAttr(block, 'title') }}</p>
                        <div>
                            <img v-for="(logo, index) in block.logos" :key="index" :src="logo.image">
                        </div>
                    </li>
            </ul>
            <Related v-if="related.length > 0" :img="img" :related="related"></Related>
            <div v-if="!mq.lgPlus">
                <Footer></Footer>
                <ul v-if="element.logo_blocks.length > 0" id="logos">
                    <li v-for="(block, index) in element.logo_blocks" :key="index">
                        <p class="st">{{ getAttr(block, 'title') }}</p>
                        <div>
                            <img v-for="(logo, index) in block.logos" :key="index" :src="logo.image">
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="mq.xxlPlus">
            <div v-if="element.media.length > 0 && mq.xxlPlus" id="video-block">
                <div v-for="(video, index) in element.media" v-on="mq.lgPlus ? { click: () => toggleFullscreen(video, index) } : {}" :key="index" class="video">
                    <div v-if="video.embed_type != 'SC'">
                    <img :src="video.thumbnail" />
                    <button><h3>Play</h3></button>
                    </div>
                    <div v-else>
                        <Video :element="video" :key="index"></Video>
                    </div>
                    <h4 v-if="video.caption_de && video.embed_type != 'SC'">{{ getAttr(video, 'caption') }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import functions from '@/functions.js'
import {store} from '@/centralStore'
import Related from '@/components/snippets/Related'
import Footer from '@/components/snippets/Footer'
import Slider from '@/components/snippets/Slider'
import Video from '@/components/snippets/Video'

export default{
    inject: ["mq"],
    name: 'Detail',
    components:{
        Related,
        Footer,
        Slider,
        Video
    },
 
    data(){
        return{
            element: null,
            programs: [],
            texts: [],
            related: [],
            markdown: 'hallo',
            img: true,
            store: store,
            video: true
        }
    },
    methods:{
        getAttr: functions.getAttr,
        getDate: functions.getDate,
        getLocale: functions.getLocale,
        sortByDate: functions.sortByDate,
        splitDate: function(date, time){
            const d = (date) ? date.split('-') : null
            const t = (time) ? time.split(':') : null
            if(!t)
                return new Date(d[0], d[1], d[2])
            else if(!d)
                return new Date(1970, 1, 1, t[0], t[1])
            else
                return new Date(d[0], d[1], d[2], t[0], t[1])
        },
        slideLeft: function(){
            const s = document.querySelector('#slider ul')
            s.prepend(s.lastElementChild)
        },
        slideRight: function(){
            const s = document.querySelector('#slider ul')
            s.append(s.firstElementChild)
        },
        toggleFullscreen(element, i){
            this.store.fullscreen = !this.store.fullscreen
            this.store.active = i
            this.store.element = element
            this.store.video = true
        },
    },
    beforeUnmount(){
        this.store.footerVisible = true
    },
    beforeMount(){
        this.store.footerVisible = false
    },
    mounted(){
        api.get('program-detail/' + this.$route.params.slug)
            .then(response => {
                this.element = response.data.element
                this.related = response.data.related
                this.programs = this.sortByDate(this.element.programs.filter( a => a.date), 'date', true)
                this.texts = this.element.programs.filter( a => a.date == null)
            })
    }

}
</script>


<style scoped>

.desktop .text, .mobile .text{
    margin-bottom: calc(var(--margin-yellow) + .9125rem);
}

.mobile.md #eventDetail, .desktop #eventDetail{
    display: flex;
}

.mobile.md #eventDetail > *, .desktop #eventDetail > *{
    width: 50%;
}

.mobile.md #eventDetail  > *:nth-child(even), .desktop #eventDetail  > *:nth-child(even){
    padding-left: .3125rem;
}

.mobile.md #eventDetail  > *:nth-child(odd), .desktop #eventDetail  > *:nth-child(odd){
    padding-right: .3125rem;
}


.desktop.xxl #eventDetail  > * {
    width: 33.33%;
    padding: 0 .625rem 0 0;
}


#info{
    margin-top: calc(var(--margin-yellow) + .0625rem);
}

#program {
    margin-bottom: .25rem;
}

#program > li > p {
    display: flex;
}

#program > li > p.gap {
    margin-top: var(--margin-yellow);
}

#program > li > h4 {
    margin-bottom: calc(var(--margin-green) - .1875rem);
}

p > small > time:not(.notime):first-of-type:not(:last-of-type)::after {
    content: ',\00a0';
}

p > small > time:not(.notime):last-of-type:after {
    content: ':\200A';
}


p > small > time:first-of-type{
    display: inline-flex;
    align-items: baseline;
}

p > small > time:first-of-type::before{
    content: '';
    border: .125rem solid var(--border);
    width: calc( var(--micro-fontsize) - var(--micro-fontsize) / 4 );
    height: calc( var(--micro-fontsize) - var(--micro-fontsize) / 4 );
    border-radius: calc( var(--micro-fontsize) - var(--micro-fontsize) / 4 );
    margin-right: .4rem;
    background: var(--text);
    flex-shrink: 0;
}

p > small > span {
    margin-left: .2rem;
}

#info {
    margin-bottom: calc(var(--margin-pink) - .1875rem);
}

#logos > li {
    margin-bottom: var(--margin-yellow);
}

#logos p{
    margin-bottom: calc( var(--margin-green) - .0625rem );
}

#logos div{
    display: flex;
    flex-wrap: wrap;
    gap: .625rem;
    
}

#logos div img{
    width: auto;
    height: 2.75rem;
}

#video-block .video:not(:last-child){
    margin-bottom: calc( var(--margin-green) - .125rem );
}

.video{
    cursor: pointer;
}

.video div {
    position: relative;
}

.video:hover button{
    background: var(--text);
    color: var(--bg);
}

.video button{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
}

#content.mobile footer + ul {
    margin-top: var(--margin-pink);
}

</style>
