import { i18n  } from '@/main.js' 

var functions = {
    getAttr(obj, attr){
        let suffix

        if (i18n.global.locale == 'de'){
            suffix = '_de'
        } else {
            suffix = '_en'
        }

        return (obj[attr + suffix] == '') ? obj[attr + '_de'] : obj[attr + suffix]
    },
    getLocale(){
        return (i18n.global.locale == 'de') ? 'de-DE' : 'en-US';
    },

    getDate(start_date, end_date){
        const s = new Date(start_date),
            e = new Date(end_date),
            sameYear = (s.getFullYear() == e.getFullYear()) ? true : false,
            locale = (i18n.global.locale == 'de') ? 'de-DE' : 'en-US';
        if(s >= e)
            return i18n.global.d(s, 'long', locale)
        else
            return `${(sameYear) ? i18n.global.d(s, 'short', locale) : i18n.global.d(s, 'long', locale)}\u2013${i18n.global.d(e, 'long', locale)}`
    },

    sortByDate(obj, method, dir) {
        return obj.sort((a, b) => {
            if(!dir)
                return new Date(b[method]) - new Date(a[method])
            else
                return new Date(b[method]) + new Date(a[method])
        });
    },

    isItFuture(date){
        return new Date(date) < new Date()
    },

    getCat(cat){
        let res;
        switch (cat) {
            case 'ALL':
                res = i18n.global.t('cat.alle')
                break;
            case 'AS':
                res = i18n.global.t('cat.ausstellung')
                break;
            case 'VE':
                res = i18n.global.t('cat.veranstaltung')
                break;
            case 'VI':
                res = i18n.global.t('cat.vitrine')
                break;
            case 'FA':
                res = i18n.global.t('cat.fanartikel')
                break;
            case 'ED':
                res = i18n.global.t('cat.editionen')
                break;
            case 'PU':
                res = i18n.global.t('cat.publikation')
                break;
            default:
                res = 'undefined'
        }
        return res;
    },

    getSubCat: function(event){
        if(event.tag_de)
            return this.getAttr(event, 'tag')
        return this.getCat(event.category)
    },

    splitDate: function(date, time){
        const d = (date) ? date.split('-') : null
        const t = (time) ? time.split(':') : null
        if(!t)
            return new Date(d[0], d[1], d[2])
        else if(!d)
            return new Date(1970, 1, 1, t[0], t[1])
        else
            return new Date(d[0], d[1], d[2], t[0], t[1])
    }
    
}

export default functions

