<template>
<div>
    <div v-if="element.embed_type == 'SC'" v-html="element.medium_id" class="video-wrapper"></div>
    <div v-else class="video-wrapper" :class="{ flag: flag }">
        <button v-if="!mq.lgPlus" @click="openFullscreen" class="fullscreen" ref="fullscreen"><h4>&#xF0015;</h4></button>
        <vue-plyr v-if="platform" ref="plyr">
            <div :data-plyr-provider="platform" :data-plyr-embed-id="video_id" data-plyr-config='{ "muted": false }'></div>
        </vue-plyr>
        <vue-plyr v-else ref="plyr">
        <video controls crossorigin playsinline>
            <source
            :src="'/api/media/'+element.video_file"
            type="video/mp4"
            />
        </video>
        </vue-plyr>
    </div>
    <h4 :class="{ ellipsis: flag }" v-if="!element.caption_de == ''">{{ getAttr(element, 'caption')}}</h4>
</div>
</template>

<script>
import {store} from '@/centralStore'
import functions from '@/functions.js'

export default{
    inject: ["mq"],
    name: 'Video',
    props: ['element', 'flag'],
    data(){
        return {
            store: store,
            player: null,
            isPlaying: false,
            video_id: this.element.medium_id,
            platform: (this.element.embed_type == null) ? null : (this.element.embed_type == 'YT') ? 'youtube' : 'vimeo'
        }
    },
    methods: {
        openFullscreen: function(){
            this.$refs.plyr.player.fullscreen.enter();
        },
        playVideo: function(event){
            this.isPlaying = !this.isPlaying
            console.log(this.isPlaying, event)
            this.$refs.plyr.player.togglePlay()
            setTimeout( () => {this.$refs.plyr.player.muted = false},1000)
        },
        getAttr: functions.getAttr,
    },
     mounted() {
        if(!this.mq.lgPlus){
            const btn = this.$refs['fullscreen']
            if(btn){
                this.$refs.plyr.player.on('playing', () => btn.classList.add('show'))
                this.$refs.plyr.player.on('pause', () => btn.classList.remove('show'))
            }
        }
        this.$nextTick(function () {
           setTimeout(() => {
               if(this.element.embed_type != 'SC'){
                this.player = this.$refs.plyr.player
                this.player.currentTime = 1
                console.log(this.player.currentTime, this.player.source)
               }
            }, 0)
        });
    }
}

</script>

<style scoped>
.video-wrapper {
    position: relative;
}

.video-wrapper.flag {
    height: 100%;
}

.video-wrapper button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-wrapper button.hide {
    display: none;
}

.video-wrapper button.fullscreen {
    top: calc(var(--margin-green) + .25rem );
    left: unset;
    right: calc(var(--margin-green) + .25rem );
    transform: none;
    width: 1.5625rem;
    height: 1.565rem;
    border-radius: 1.565rem;
    padding: 0;
    padding-bottom: .0625rem;
    border: none;
    display: none;
}

.video-wrapper button.fullscreen.show {
    display: block;
}

div:not(.video-wrapper){
    margin-bottom: calc(var(--margin-green) - .0625rem);
}

h4 {
    margin-top: var(--margin-orange);
}

h4.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
