<template>
  <div>
     <ul>
      <li :class="filterStatus ? 'no-show' : 'show' "><button @click="changeFilter($event)" v-html="filterStatus ? '<h3>Filter</h3>' : '<h4>&#xF0011;</h4>'"></button></li>
      <li v-for="cat in possibleCat" :key="cat" :class="classCat(cat)"><button @click="changeCat(cat)"><h3>{{getCat(cat)}}</h3></button></li>
    </ul>
    <masonry v-if="filterEventsByCategory" :cols="{default: 3, 1439: 2, 767: 1}" :gutter="10">
      <Item v-for="(item, index) in filterEventsByCategory" :event="item" type="ShopItem" :key="index"></Item>
    </masonry>
  </div>
</template>

<script>
import api from '@/api'
import functions from '@/functions.js'
import Item from '@/components/snippets/Item';

export default {
    inject: ["mq"],
    components: {
      Item
    },
    data(){
      return {
        items: [],
        category: 'ALL',
        categories: [],
        filteredItems: [],
        filterStatus: true
      }
    },
    methods:{
      changeCat(obj){
        return this.category = obj 
      },
      classCat(obj){
        return (obj == this.category) ? 'active' : ''
      },
      changeFilter(){
        this.category = (!this.filterStatus) ? 'ALL' : this.category
        return this.filterStatus = !this.filterStatus
      },
      getCat: functions.getCat,
      sortByDate: functions.sortByDate
    },
    computed: {
      possibleCat() {
        const category = this.items.map( a => a.category)
        //category.unshift('ALL')
        return [...new Set(category)]
      },
      filterEventsByCategory: function(){
        return this.items.filter( a => {
          if(this.category == 'ALL')
            return a
          else
            return a.category == this.category
        })
      }
    },
    mounted(){
        api.get('shop/')
            .then(response => {
            this.items = this.sortByDate(response.data.items, "start_date")
            this.filteredItems = this.items.filterEventsByCategory
        })
    }
}
</script>

<style scoped>
main > div {
  margin-top: .25rem;
  margin-bottom: .0625rem;
}

div > ul:first-of-type  {
  display: inline-flex;
  flex-wrap: wrap;
}

div ul:first-of-type > li {
  margin-right: calc(var(--margin-green) + .1875rem);
  margin-bottom: calc(var(--margin-green) + .125rem);
}

.active button{
  background-color: var(--text);
  color: var(--bg);
}


ul + div{
  margin-top: 1rem;
}

li.show button{
  padding: 0 .35rem;
  width: var(--button);
  flex-shrink: 0;
  padding-bottom: 0.0625rem;
}

div > ul > li:not(.active) > button + ul:first-of-type{
  display: none;
}

li.no-show ~ li {
  display: none;
}
</style>