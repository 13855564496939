<template>
  <div class="column-wrap">
    <div>
      <p>{{ $t('text.mitgliedschaft') }}</p>
    </div>
    <div>
      <p>{{ $t('text.vorteile') }}</p>
      <ul>
        <li v-for="(item, index) in $t('text.mitgliedschaft_list').split('*list*')" :key="index"><p><span></span>{{ item }}</p></li>
      </ul>
      <button @click="toggleMember()"><h3>{{$t('title.mitgliedwerden')}}</h3></button>
    </div>
  </div>
</template>

<script>
import {store} from '@/centralStore'

export default {
    inject: ["mq"],
    data(){
        return{
            store: store,
        }
    },
    methods: {
        toggleMember(){
            this.store.memberVisible = !this.store.memberVisible
        }
    }
}
</script>
<style scoped>
.desktop .column-wrap{
    display: flex;
}

.desktop .column-wrap > *{
    width: 50%;
}

.desktop .column-wrap > *:nth-child(even){
    padding-left: .3125rem;
}

.desktop .column-wrap > *:nth-child(odd){
    padding-right: .3125rem;
}


.desktop.xxl .column-wrap > *{
    width: 33.33%;
    padding: 0 .625rem 0 0;
}

div.column-wrap {
  margin-bottom: .2375rem;
}

ul {
  margin-top: var(--margin-yellow);
}

ul > li > p {
  display: inline;
  align-items: baseline;
  
}

ul > li > p > span::before{
    content: '';
    border: .09375rem solid var(--border);
    width: calc( var(--base-fontsize) - var(--base-fontsize) / 4 );
    height: calc( var(--base-fontsize) - var(--base-fontsize) / 4 );
    border-radius: calc( var(--base-fontsize) - var(--base-fontsize) / 4 );
    flex-shrink: 0;
    background: var(--text);
    display: inline-flex;
}

button:hover {
 color: var( --bg);
 background: var(--accent);
}

button{
  margin-top: calc(var(--margin-green) - .0625rem);
  width: 100%;
  color: var( --accent);
  border-color: var(--accent);
}


</style>