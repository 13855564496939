import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Vue3Mq  } from "vue3-mq";

import MasonryWall from '@yeger/vue-masonry-wall'
import masonry from 'vue-next-masonry'

import { createI18n} from "vue-i18n";
import { translations } from './translations'
import { datetimeFormats } from './datetimeFormats'
import { numberFormats } from './numberFormats'

import VueSnip from 'vue-snip'

import VuePlyr from '@skjnldsv/vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

export const i18n = createI18n({
    legacy: true,
    //locale: 'de',
    locale: (localStorage.getItem('lang') || 'de'), // set default locale
    messages: translations,
    datetimeFormats,
    numberFormats
})

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(Vue3Mq, {
      breakpoints: { 
          xs: 0,
          sm: 520,
          md: 768,
          lg: 1024,
          xl: 1286,
          xxl: 1440,
          xxxl: 4096,
        }
})


app.use(VueSnip)
app.use(MasonryWall)
app.use(masonry)

app.use(VuePlyr, {
    plyr: {
        invertTime: false,
        toggleInvert: false,
        controls: ['play-large','progress', 'current-time'],
        hideControls: false,
        fullscreen: { iosNative: true }
    }
})

app.mount('#app')
