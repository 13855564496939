<template>
<div id="overlay" :class="{ start: store.startscreen }" v-on="store.startscreen ? { click: () => toggleStartscreen() } : {}">
    <ul v-if="!store.video && !store.startscreen" id="list">
        <li v-for="(image, index) in slider" :key="index" :data-index="index">
            <figure>
                <div :class="{fullscreen : slider.length <= 1 && !image.caption_de && !image.caption_en}" >
                    <img :src="image.thumbnail_very_large">
                    <button v-if="slider.length > 1" v-on:click="slideLeft"><h4>&#x2190;</h4></button>
                    <button @click="toggleFullscreen(slider)" class="fullscreen"><h4>&#xF0011;</h4></button>
                    <button v-if="slider.length > 1" v-on:click="slideRight"><h4>&#x2192;</h4></button>
                </div>
                <figcaption v-if="slider.length > 1 || image.caption_de || image.caption_en" ><span v-if="slider.length > 1" class="counter">{{index+1}}/{{slider.length}}</span><span class="ellipsis">{{ getAttr(image, 'caption') }}</span></figcaption>
            </figure>
        </li>                          
    </ul>
    <div v-else-if="!store.startscreen">
        <button @click="toggleFullscreen(slider)" class="fullscreen"><h4>&#xF0011;</h4></button>
        <Video :element="element" :flag="true"></Video>
    </div>
    <div v-else id="startscreen">
        <h1 class="tight">{{ $t("title.home") }}</h1>
        <ul>
            <li v-for="(item, index) in store.events" :key="index">
                <h1 class="tight" v-if="item.category == 'VI'">{{ $t('cat.vitrine') }}</h1>
                <h2 class="tight"><time>{{ getDate(item.start_date, item.end_date) }}</time></h2>
                <h2 v-if="item.group_show"><em>{{ getAttr(item, 'title') }}</em></h2>
                <ul v-if="item.display_artists">
                    <li v-for='artist in item.artists' :key='artist'><h2>{{artist}}</h2></li>
                </ul>
                <h2 v-if="!item.group_show"><em>{{ getAttr(item, 'title') }}</em></h2>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import {store} from '@/centralStore'
import functions from '@/functions.js'
import Video from '@/components/snippets/Video'

export default {
    name: 'Fullscreen',
    props: ['slider'],
    components: {
        Video
    },
    data(){
        return{
            store: store,
            element: this.slider
        }
    },
    methods: {
        slideLeft: function(){
            const s = document.querySelector('#overlay ul')
            s.prepend(s.lastElementChild)
            if (this.gift)
                this.autoCheck(s.firstElementChild)
            
        },
        slideRight: function(){
            const s = document.querySelector('#overlay ul')
            s.append(s.firstElementChild)
            if (this.gift)
                this.autoCheck(s.firstElementChild)
        },
        toggleFullscreen(){
            this.store.fullscreen = !this.store.fullscreen
            this.store.element = null
            this.store.video = false
        },
        toggleStartscreen(){
            const b = document.querySelector('body')
            b.classList.add('transition')
            setTimeout(() => {
                b.classList.remove('transition')
                this.store.startscreen = false
            }, 1000)
        },
        handleKeyDown(event) {
            if (event.keyCode === 37){
                this.slideLeft()
            }
            
            if(event.keyCode === 39){
                this.slideRight()
            }

            if(event.keyCode === 27){
                this.toggleFullscreen()
            }
        },
        getCat: functions.getCat,
        getAttr: functions.getAttr,
        getLocale: functions.getLocale,
        getDate: functions.getDate,
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    mounted () {
        document.body.classList.add('no-scroll')
        window.addEventListener('keydown', this.handleKeyDown);
        const overlay = document.getElementById('overlay')
        if (!this.store.video && !this.store.startscreen){
            const list = document.getElementById('list')
            for (let i = 0; i < this.store.active; i++) {
                list.append(overlay.querySelector('li[data-index="'+ i +'"]'))
            }
        }
    },
    unmounted () {
        document.body.classList.remove('no-scroll')
    }
}



</script>


<style scoped>
#overlay{
    position: fixed; 
    top: 0; right: 0; bottom: 0; left: 0; 
    width: 100%;
    height: 100%;
    padding: .625rem;
    background: rgba(0,0,0,0.06);
}

#overlay.start {
    padding: 0;
}

#overlay > ul, #overlay > ul > li, #overlay > ul > li > figure{
    width: inherit;
    height: inherit;
}

#overlay > div {
    height: inherit;
    width: inherit;
}

#overlay > div > div {
    height: inherit;
    width: inherit;
}
#overlay > div > div {
    height: calc(100% - var(--base-fontsize) - .1875rem ) !important;
    width: inherit;
}

figure div {
    height: calc(100% - var(--base-fontsize) - .1875rem );
}

figure div.fullscreen {
    height: 100%;
}

ul li figure img{
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: left top;
}

div > button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 1.5625rem;
    height: 1.565rem;
    border-radius: 1.565rem;
    padding: 0;
    padding-bottom: .0625rem;
    border: none;
}

figure > div > button:not(.fullscreen):first-of-type {
    left: 1.25rem;
}

figure > div > button:not(.fullscreen):last-of-type {
    right: 1.25rem;
}

div > button.fullscreen {
    right: 1.25rem;
    margin: 0;
    top: 2.0625rem;
    z-index: 999;
}

ul:first-of-type > li {
    display: none;
}

ul:first-of-type > li:first-of-type {
    display: block;
}

figcaption {
    padding-top: .3125rem;
    margin-bottom: -.25rem;
}

figcaption span:first-of-type{
    margin-right: var(--margin-green);
}

#startscreen{
    overflow: scroll;
    padding: .5rem var(--frame);
    cursor: pointer;
}

#startscreen ul li {
 display: block;
}

#startscreen > ul > li:not(:last-of-type) {
 margin-bottom: 10.2vw;
}

#startscreen h1, #startscreen h2 {
    font-size: 9.3vw;
    line-height: .95;
}

.mobile #startscreen h1, .mobile #startscreen h2{
    font-size: 9.1vw;
}

.mobile.md #startscreen h1, .mobile.md #startscreen h2{
    font-size: 9.2vw;
}

.desktop:not(.lg) #startscreen h1, .desktop:not(.lg) #startscreen h2{
    font-size: 9.35vw;
}

#startscreen .tight {
    line-height: .84;
}

#startscreen h1.tight{
    font-feature-settings: "ss01" 1, "ss02" 1, "ss04" 1; 
    font-variation-settings: "wght" 90, "hght" 491, "prtn" 500;
}

.transition #overlay{
    opacity: 0;
    transition: .5s;
}

span.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


</style>
