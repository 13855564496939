<template>
<div class="column">
  <ul>
      <ElementShort v-for="event in events" :event="event" :tag="tagStatus(event)" :key="event.pk"></ElementShort>
  </ul>
</div>
</template>

<script>
import api from '@/api'
import ElementShort from '@/components/snippets/ElementShort'
import functions from '@/functions.js'
export default {
  inject: ["mq"],
  components: {
        ElementShort,
  },
  data(){
    return{
      events: []
    }
  },
  methods: {
    tagStatus: function(e) {
          if (e.class == 'Event' && e.category != 'VE')
            return this.isItFuture(e.start_date)
          return true
    },
    isItFuture: functions.isItFuture,
    sortByDate: functions.sortByDate
  },
  mounted(){
    api.get('current/')
      .then(response => {
        this.events = this.sortByDate(response.data.current_events, 'start_date', false)
        const iterator = this.sortByDate(response.data.future_events, 'start_date', true).values()
        console.log(iterator)
        for (const value of iterator){
            this.events.push(value)
        }
      })
  }
}

</script>
<style scoped>
div.column {
    margin-bottom: .205rem;
}
</style>