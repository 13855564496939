<template>
    <header v-bind:class="{ nogap: $route.name == 'search'}">
        <div v-if="$route.name == 'search'">
            <button v-if="results" @click="toggleClose"><h4>&#xF0011;</h4></button>
            <div v-else><img class="spinner" src="~@/assets/spinner.svg"></div>
            <h1><span>{{ $t("title."+$route.name+"_long") }}</span><em>{{ $route.params.searchQuery }}</em></h1></div>
        <h1 v-else>{{ $t("title."+$route.name) }}&#x2003;</h1>
        <h2 v-if="$route.name != 'search'"><a role="button" tabindex="0" v-if="mq.lgMinus" @click="toggleMenu">Menü</a></h2>
    </header>
</template>

<script>
import {store} from '@/centralStore';
export default {
    inject: ["mq"],
    props: ["results"],
    data(){
        return {
            store: store,
        }
    },
    methods :{
        toggleMenu(){
            this.store.menuVisible = !this.store.menuVisible;
        },
        toggleClose(){
            //console.log('closeTo: ', this.store.lastRoute)
            this.$router.push({ path: this.store.lastRoute })
        }
    }
}


</script>


<style scoped>
header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--margin-pink);
}

header.nogap {
    margin-bottom: 0;
}

button{
    padding: 0 .35rem;
}

a[role="button"]{
    cursor: pointer;
}

header > div {
    display: inline-flex;
    align-items: center;
}

header > div > h1 > span {
    display: block;
}

.desktop header > div > h1 > span {
    display: inline;
    margin-right: .25rem;
}

header > div > button {
    margin-right: var(--margin-green);
    width: var(--button);
    height: var(--button);
    flex-shrink: 0;
    padding-bottom: 0.0625rem;
}

.mobile header > div{
    width: 100%;
    justify-content: space-between;
}

.mobile header > div > button, .mobile header > div > div{
    margin: 0;
    order: 2;
}

.mobile header > div > div .spinner{
    margin: 0;
}

header > div > div {
    height: var(--button);
    flex-shrink: 0;
}

.spinner {
    margin-right: var(--margin-green);
    width: var(--button);
    padding-bottom: 0.0625rem;
    animation: spinner infinite 1s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
