<template>
<div v-if="element" id="annualGiftDetail">
    <div>
        <ul>
            <li v-for='artist in element.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2><em>{{ getAttr(element, 'title') }}</em></h2>
        <h2 v-if="element.price && element.available">{{ $n(element.price, 'currency', getLocale()) }}</h2>
        <h2 v-else-if="!element.price && element.available">{{ $t('title.gratis') }}</h2>
        <h2 v-else>({{ $t('title.vergriffen') }})</h2>
        <Slider :images="element.images" :unique="element.unique" :element="element" :gift="true"></Slider>
    </div>
    <div>
        <p class="text" v-html="getAttr(element, 'text')"></p>
        <div id="info" v-if="element.info_text_de != ''">
            <p class="st"><small v-html="getAttr(element, 'info_text')"></small></p>
        </div>
        <Related v-if="related.length > 0" :img="img" :related="related"></Related>
    </div>
</div>
</template>


<script>
import api from '@/api'
import functions from '@/functions.js'
import Slider from '@/components/snippets/Slider'
import {store} from '@/centralStore'
import Related from '@/components/snippets/Related.vue'

export default{
    inject: ["mq"],
    name: 'DetailGift',
    components: {
        Slider,
        Related
    },
    data(){
        return{
            element: null,
            related: null,
            img: true,
            store: store
        }
    },
    methods:{
        getAttr: functions.getAttr,
        getDate: functions.getDate,
        getLocale: functions.getLocale
    },
    mounted(){
        api.get('gift-detail/' + this.$route.params.slug)
            .then(response => {
                this.element = response.data.element
                this.related = response.data.related
            })
    }

}
</script>

<style scoped>
.mobile.md #annualGiftDetail, .desktop #annualGiftDetail{
    display: flex;
}

.mobile.md #annualGiftDetail > *, .desktop #annualGiftDetail > *{
    width: 50%;
}

.mobile.md #annualGiftDetail  > *:nth-child(even), .desktop #annualGiftDetail  > *:nth-child(even){
    padding-left: .3125rem;
}

.mobile.md #annualGiftDetail  > *:nth-child(odd), .desktop #annualGiftDetail  > *:nth-child(odd){
    padding-right: .3125rem;
}


.desktop.xxl #annualGiftDetail  > *{
    width: 33.33%;
    padding: 0 .625rem 0 0;
}

#info {
    margin-top: calc(var(--margin-yellow) + 0rem);
}

#info p{
    font-size: var(--micro-fontsize);
}

</style>
