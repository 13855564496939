<template>
<div v-if="element" id="shopDetail">
    <div>
        <ul>
            <li v-for='artist in element.artists' :key='artist'><h2>{{artist}}</h2></li>
        </ul>
        <h2><em>{{ getAttr(element, 'title') }}</em></h2>
        <h2 v-if="element.price && element.available">{{ $n(element.price, 'currency', getLocale()) }}</h2>
        <h2 v-else>({{ $t('title.vergriffen') }})</h2>
        <Slider :images="element.images" :unique="element.unique" :element="element" :shop="true"></Slider>
    </div>
    <div>
        <p class="text" v-html="getAttr(element, 'text')"></p>
        <div id="info" v-if="element.info_text_de != ''">
            <p class="st"><small v-html="getAttr(element, 'info_text')"></small></p>
        </div>
        <Related v-if="related.length > 0" :img="img" :related="related"></Related>
    </div>
</div>
</template>


<script>
import api from '@/api'
import functions from '@/functions.js'
import Slider from '@/components/snippets/Slider'
//import OrderForm from '@/components/snippets/OrderForm'
import {store} from '@/centralStore'
import Related from '@/components/snippets/Related.vue'

export default{
    inject: ["mq"],
    name: 'DetailShop',
    components: {
        Slider,
        Related
        //OrderForm
    },
    data(){
        return{
            element: null,
            store: store,
            img: true,
            related: null
        }
    },
    methods:{
        getAttr: functions.getAttr,
        getDate: functions.getDate,
        getLocale: functions.getLocale,
        toggleOrder(){
            this.store.orderVisible = !this.store.orderVisible
            this.store.element = this.element
        }
    },
    mounted(){
        api.get('shop-detail/' + this.$route.params.slug)
            .then(response => {
                    this.element = response.data.element
                    this.related = response.data.related
            })

    }

}


</script>


<style scoped>
.mobile.md #shopDetail, .desktop #shopDetail{
    display: flex;
}

.mobile.md #shopDetail > *, .desktop #shopDetail > *{
    width: 50%;
}

.mobile.md #shopDetail  > *:nth-child(even), .desktop #shopDetail  > *:nth-child(even){
    padding-left: .3125rem;
}

.mobile.md #shopDetail  > *:nth-child(odd), .desktop #shopDetail  > *:nth-child(odd){
    padding-right: .3125rem;
}

.desktop.xxl #shopDetail  > *{
    width: 33.33%;
    padding: 0 .625rem 0 0;
}

#slider{
    margin-bottom: calc(var(--margin-yellow) + .25rem);
}

#info {
    margin-top: calc(var(--margin-yellow) + 0rem);
}

#info p{
    font-size: var(--micro-fontsize);
}
</style>
