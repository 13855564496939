<template>
    <div id="content" :class="[mq.lgPlus ? 'desktop' : 'mobile', mq.current]" v-cloak>
        <Menu v-if="store.menuVisible || mq.lgPlus"></Menu>
        <main v-if="(!store.menuVisible || mq.lgPlus)" v-cloak>
            <Header v-if="!mq.lgPlus && $route.name != 'search'" :results="true"></Header>
            <router-view :key="$route.path"></router-view>
            <Footer v-if="!mq.lgPlus && store.footerVisible"></Footer>
        </main>
        <OrderForm v-if="store.orderVisible" :order="store.element"></OrderForm>
        <MemberForm v-if="store.memberVisible"></MemberForm>
        <Fullscreen v-if="store.fullscreen || (store.startscreen && $route.name == 'home')" :slider="store.element"></Fullscreen>
    </div>
</template>


<script>
import {store} from '@/centralStore'
import Header from '@/components/snippets/Header'
import Menu from '@/components/snippets/Menu'
import OrderForm from '@/components/snippets/OrderForm'
import MemberForm from '@/components/snippets/MemberForm'
import Fullscreen from '@/components/snippets/Fullscreen'
import Footer from '@/components/snippets/Footer'
import api from '@/api'

export default{
    inject: ["mq"],
    components: {
        Header,
        Menu,
        Footer,
        OrderForm,
        MemberForm,
        Fullscreen
    },
    data(){
        return {
            store: store
        }
    },
    mounted(){
        let token
        api.get('get-csrf')
            .then(response => {
                token = response.data.token
                api.defaults.headers.common['X-CSRFToken'] = token;
            })

    }
}

</script>

<style>

@font-face {
  font-family: 'kvhbf';
  src: url('~@/assets/KvHBfWeb/KvHBfWebGX.ttf') format('truetype-variations');
}

@font-face {
  font-family: 'kvhbf fb';
  src: url('~@/assets/KvHBfWeb/KvHBfWeb-Regular90NordItalic.woff') format('woff');
  font-style: italic;
}

@font-face {
  font-family: 'kvhbf fbm';
  src: url('~@/assets/KvHBfWeb/KvHBfWeb-Book100NordItalic.woff') format('woff');
  font-style: italic;
}

@font-face {
  font-family: 'kvhbf fbb';
  src: url('~@/assets/KvHBfWeb/KvHBfWeb-Bold110NordItalic.woff') format('woff');
  font-style: italic;
}

:root {
    --sans-font: 'kvhbf', Helvetica, Arial, sans-serif;

    --heading-fontsize: 1.25rem;
    --base-fontsize: 1rem;
    --small-fontsize: .9375rem;
    --micro-fontsize: .75rem;
    
    --line-height: 1.1;

    --bg: #fff;
    --text: #000;
    --text-alt: #adadad;
    --border: #000;
    --accent: #ff00c4;
    
    --margin-yellow: .8125rem;
    --margin-green: .5rem;
    --margin-orange: .125rem;
    --margin-pink: 1.5rem;

    --button: 1.565rem;

    --frame: .40675rem;
    --frameh: .625rem;

    --plyr-color-main: #fff;
    --plyr-video-background: none;
    --plyr-video-progress-buffered-background: rgba(255, 255, 255, 1);
    --plyr-range-thumb-height: var(--button);
    --plyr-range-thumb-background: #000;
    --plyr-range-fill-background: #000;
    --plyr-range-track-height: var(--button);
    --plyr-range-thumb-active-shadow-width: 0;
    --plyr-range-thumb-shadow: 0;
    --plyr-video-controls-background: none;
}

.plyr--full-ui input[type=range] {
      overflow: hidden;
}
.plyr--full-ui input[type=range]::-webkit-slider-thumb {
      width: 3.6rem !important; 
      border-radius: 0 1.5rem 1.5rem 0 !important;
}
.plyr__progress__buffer {
      display: none;
}
.plyr__controls__item.plyr__time {
      position: absolute;
      left: .7rem;
      z-index: 3;
}

.plyr__control::before {
      content: "Play";
      color: var(--text);
      position: absolute;
      top: 0;
      left: .55rem;
      font-size: var(--small-fontsize);
      font-family: var(--sans-font);
      font-feature-settings: "ss01" 1, "ss02" 1, "ss04" 1;
      font-variation-settings: "wght" 90,"hght" 536, "prtn" 0;
}

.plyr__controls__item.plyr__time {
    font-size: var(--small-fontsize);
    font-family: var(--sans-font);
    font-feature-settings: "ss01" 1, "ss02" 1, "ss04" 1;
	font-variation-settings: "wght" 90,"hght" 536, "prtn" 0;
}

.plyr button.plyr__control--overlaid{
    opacity: 1;
    padding: 0rem;
    height: var(--button);
    width: 3rem;
    color: var(--text);
    background: var(--bg);
    border: none;
    border-radius: var(--base-fontsize);
    box-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    transition: none;
}

.plyr button.plyr__control--overlaid:hover{
    background: var(--text);
}

.plyr button.plyr__control--overlaid:hover::before{
    color: var(--bg);
}

.plyr button.plyr__control--overlaid svg{
      display: none;
}

.plyr__controls {
    opacity: 0;
}

.plyr--playing .plyr__controls {
    opacity: 1;
}

.mobile.xs .plyr__controls .plyr__controls__item.plyr__time {
    padding: 0;
}

.no-scroll { overflow: hidden; }

.no-scroll main, .no-scroll nav {
    filter: blur(1rem);
    opacity: .5;
}

.no-scroll.transition main, .no-scroll.transition nav {
    filter: blur(0);
    transition: 1s;
    opacity: 1;
}

html {
    box-sizing: border-box;
    font-size: 16px;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 1.1vw;
  }
}

@media only screen and (max-width: 1999px) {
  html {
    font-size: 1.111vw;
  }
}

@media only screen and (max-width: 1439px) {
  html {
    font-size: 1.563vw;
  }
}

@media only screen and (max-width: 1023px) {
  html {
    font-size: 2.083vw;
  }
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 22px;
  }
}

@media only screen and (max-width: 519px) {
  html {
    font-size: 4.267vw;
  }
}

*, *:before, *:after {
    box-sizing: inherit;
}
  
body, h1, h2, h3, h4, h5, h6, p, ol, ul, figure {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: unset;
}

ol, ul {
    list-style: none;
}
  
img {
    width: 100%;
    height: auto;
}
a {
    text-decoration: none;
}

h4 > a, p a {
    color: var(--text-alt);
}

h4 > a::before, p a:not(.na)::before {
    content: "→";
    margin-right: var(--margin-orange);
}

h4 > a:hover, p a:hover {
    color: var(--text);
}

#app {
    font-family: var(--sans-font);
    color: var(--text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;
    padding: var(--frame) var(--frameh);
    height: inherit;
}

main {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}




main > ul > li:not(:last-of-type){
    margin-bottom: 0 var(--margin-yellow);
}

textarea,
select,
input, 
button {
  font-family: inherit;
  padding: 0 .6rem;
  height: var(--button);
  color: var(--text);
  background: var(--bg);
  border: .0625rem solid var(--border);
  border-radius: var(--base-fontsize);
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-variation-settings: "wght" 90,"hght" 536,"prtn" 0;
}

button > h4 {
    font-variation-settings: "wght" 90,"hght" 536,"prtn" 0;
}

select + label > p {
    font-variation-settings: "wght" 90,"hght" 536,"prtn" 0;
    padding-bottom: .1rem;
}


input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

button > h3 {
    margin-bottom: .125rem;
}

h4, small { font-size: var(--micro-fontsize); }

figcaption { 
    margin-top: var(--margin-orange);
    line-height: var(--line-height);
    display: flex;
}

a:hover > button[disabled], button:not([disabled]):hover {
    color: var(--bg);
    background: var(--text);
    cursor: pointer;
}

@media (hover: none) {
  a:hover > button[disabled], button:not([disabled]):hover {
        color: inherit;
        background: inherit;
    }
}

figure {
    display: flex;
    flex-direction: column;
}

[v-cloak]{
    display: none;
}

em {
    font-family: 'kvhbf fb';
}

 p em {
    font-family: 'kvhbf fbm';
}

h4 em, small em, figcaption em {
    font-family: 'kvhbf fbb';
}

h1, h2 {
	font-variation-settings: "wght" 90, "hght" 536, "prtn" 0;
    font-size: var(--heading-fontsize);
    line-height: 1;
}

h1, header h2 {
    font-variation-settings: "wght" 90, "hght" 536, "prtn" 500;
    font-feature-settings: "ss01" 1, "ss02" 1, "ss04" 1;
}

footer p {
	font-variation-settings: "wght" 90, "hght" 536, "prtn" 0;
    font-size: var(--heading-fontsize);
    line-height: 1;
}

footer p:not(.st){
    line-height: 1;
}


footer p span {
	font-variation-settings: "wght" 105, "hght" 536, "prtn" 0;
}

.mobile footer * {
    font-variation-settings: "wght" 90, "hght" 536, "prtn" 0;
}

.desktop footer p {
    font-size: .9375rem;
    line-height: 1.0625;
}

h3, input, select, textarea, input + label {
	font-variation-settings: "wght" 90,"hght" 536, "prtn" 0;
    font-size: var(--small-fontsize);
}

input[type="text"], input[type="tel"], input[type="email"], input[type="number"] {
    padding-bottom: .1rem;
}

figcaption, h4, small, #logos p{
    font-size: var(--micro-fontsize);
    line-height: 1.2;
	font-variation-settings: "wght" 110,"hght" 536,"prtn" 0
}

button > h4 {line-height: var(--line-height);}

figcaption > span.counter { font-feature-settings: "tnum"; }

button > h3 {
    font-feature-settings: "tnum";
}

p {
    font-size: 1rem;
	font-variation-settings: "wght" 100, "hght" 536, "prtn" 0;
}

p:not(.st) {
    line-height: 1.125rem;
}

#content.desktop nav {
    position: fixed;
    width: calc(33.333% - var(--frame) * 1.5);
}

#content.desktop.xxl nav {
    width: calc(25% - var(--frame) * 1.5);
}

#content.desktop main {
    width: 100%;
    padding-left: 33.333%;
}

#content.desktop.xxl main {
    width: 100%;
    padding-left: 25%;
}
/*
#content.desktop main > ul, #content.desktop main > div {
    width: 100%;
}
*/

#content.desktop main > div.column { 
  width: 50%;
}

#content.desktop.xxl main > div.column { 
  width: 33.33%;
}
</style>

