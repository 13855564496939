<template>
<ul id="related">
    <h4>{{ $t('title.related')}}</h4>
    <ElementShort v-for="element in related" :event="element" :img="img" :key="element" :tag="tagStatus(element)"></ElementShort>
</ul>
</template>

<script>
import ElementShort from '@/components/snippets/ElementShort'
import functions from '@/functions.js'

export default {
    inject: ["mq"],
    name: 'Related',
    props: ['img', 'related'],
    components: { ElementShort },
    methods: {
        tagStatus: function(e) {
            if (e.class == 'Event' && e.category != 'VE')
                return this.isItFuture(e.start_date)
            return true
        },
        isItFuture: functions.isItFuture,
        sortByDate: functions.sortByDate
    }
}
</script>

<style scoped>
ul#related {
    margin-top: var(--margin-pink);
}

ul#related > li:last-of-type {
    margin-bottom: .0625rem;
}

h4 {
    margin-bottom: calc(var(--margin-yellow) + .625rem);
}
</style>
