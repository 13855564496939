<template>
  <div>
    <ul v-for="(year, index) in years" :key="index">
      <li v-bind:class="{ active: year.year === this.yearFilter }">
        <ul>
          <li><button @click="loadYear(year)"><h3>{{year.year}}</h3></button></li>
          <li v-if="year.loaded" :class="filterStatus ? 'no-show' : 'show' "><button @click="changeFilter($event)" v-html="filterStatus ? '<h3>Filter</h3>' : '<h4>&#xF0011;</h4>'"></button></li> 
          <li v-else class="no-show"><img class="spinner" src="~@/assets/spinner.svg"></li>
          <li v-for="cat in possibleCat" :key="cat" :class="classCat(cat)"><button @click="changeCat(cat)"><h3>{{getCat(cat)}}</h3></button></li>
        </ul>
          <masonry v-if="year.loaded && show" :cols="{default: 3, 1439: 2, 767: 1}" :gutter="10">
              <Element v-for="(item, index) in filterEventsByCategory" :event="item" :key="index" :tag="true"></Element>
          </masonry>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/api'
import functions from '@/functions.js'
import Element from '@/components/snippets/Element'
import {store} from '@/centralStore'
import _ from 'lodash'

export default {
    inject: ["mq"],
    data(){
        return{
            events: [],
            loadedEvents: [],
            category: 'ALL',
            categories: [],
            filterStatus: true,
            years: [],
            yearFilter: null,
            show: false,
            store: store
        }
    },
    components: {
        Element,
    },
    methods:{
      loadYear(obj){
        if(obj === undefined) return
        this.years.map(el => el.loaded = false)
        this.yearFilter = obj.year
         api.get('archive-year/' + obj.year)
            .then(response => {
              obj.events = response.data.events
              obj.loaded = true
              this.loadedEvents = obj.events
              const l = this.loadedEvents.filter( a => a.category == this.category ).length
              if(l < 1) this.filterStatus = true, this.category = 'ALL'
              this.updateQueryParams()
        })
      },
      changeCat(obj){
        return this.category = obj
      },
      classCat(obj){
        return (obj == this.category) ? 'active' : ''
      },
      changeFilter(){
        this.category = (!this.filterStatus) ? 'ALL' : this.category
        return this.filterStatus = !this.filterStatus
      },
      updateQueryParams() {
        const query = {}

        if (this.yearFilter != null) {
          query.year = this.yearFilter
        }

        if (this.category != 'ALL') {
          query.category = this.category
          this.filterStatus = false
        }

        
        if (!_.isEqual(this.$route.query, query)) {
          if (store.tinkerRoute.fullPath === '/programm/archiv')
            this.$router.replace({ query: query })
          else
            this.$router.push({ query: query })
        }
        
      },
      getCat: functions.getCat,
      sortByDate: functions.sortByDate,
    },

    watch: {
      
      '$route.query.year'() {
          this.loadYear(this.years.filter(a => a.year === parseInt(this.$route.query.year))[0])
      },
      
      '$route.query.category'() {
          this.category = (this.$route.query.category === undefined) ? 'ALL' : this.$route.query.category
          if (this.category === 'ALL') this.filterStatus = true
      }
  
    },

    computed: {
      possibleCat() {
        const category = this.loadedEvents.map( a => a.category)
        return [...new Set(category)]
      },
      filterEventsByCategory: function(){
        let catFilter = this.loadedEvents.filter( a => {
          if(this.category == 'ALL')
            return a
          else
            return a.category == this.category
        })
        return catFilter
      },
    },


    updated: function () {
      this.$nextTick(function () {
        window.scrollTo(0, 0)
        this.show = true
        this.updateQueryParams()
      })
    },

    mounted(){
        api.get('archive-year/')
            .then(response => {
              if ('year' in this.$route.query) {
                this.yearFilter = parseInt(this.$route.query.year)
              }
              const data = response.data.year_range.reverse()
              const filteredYear = (data.includes(this.yearFilter)) ? this.yearFilter : data[0]
              this.yearFilter = filteredYear

              api.get('archive-year/' + filteredYear )
                .then(response => {
                  const loaded = response.data.events
                  this.loadedEvents = loaded
                  this.years = data.map((a, index) => {
                    return {
                      year: a,
                      events: (index === 0) ? loaded : null,
                      loaded: (a === filteredYear) ? true : false
                    }
                  })
                  const cat = this.possibleCat
                  if (cat.includes(this.$route.query.category))
                    this.category = this.$route.query.category
              })
            })
    }
}

</script>
<style scoped>

main > div {
  margin-top: .25rem;
}

div ul li > ul:first-of-type  {
  display: inline-flex;
  flex-wrap: wrap;
}

li.active > ul > li:first-of-type > button, li.active > button{
  background-color: var(--text);
  color: var(--bg);
}

li.show button{
  padding: 0 .35rem;
  width: var(--button);
  flex-shrink: 0;
  padding-bottom: 0.0625rem;
}

li.no-show ~ li {
  display: none;
}

ul > li > ul > li {
  margin-right: calc(var(--margin-green) + .125rem);
  margin-bottom: calc(var(--margin-green) + .125rem);
}

div > ul > li:not(.active) > div {
  display: none !important;
}

div > ul:not(:last-of-type) > li.active > div {
  margin-bottom: 1.6875rem;
}

li.active > div{
  margin-top: 1rem;
}

div > ul > li:not(.active) > ul > li:first-of-type ~ li {
  display: none;
}

div > ul:last-of-type > li > ul > li {
  margin-bottom: 0;
}

.spinner {
  height: var(--button);
  width: auto;
  animation: spinner infinite 1s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>