export const translations = {
        de: {
            title: {
                undefined: 'undefined',
                home: 'Kunstverein Harburger Bahnhof',
                programm: 'Programm',
                detail: 'Programm',
                aktuell: 'Aktuell',
                archiv: 'Archiv',
                jahresgaben: 'Jahresgaben',
                detailGift: 'Jahresgaben',
                ueber: 'Über',
                agenda: 'Agenda',
                team: 'Team',
                geschichte: 'Geschichte',
                mitgliedschaft: 'Mitgliedschaft',
                mitgliedwerden: 'Mitglied werden',
                mitgliedsantrag: 'Mitgliedsantrag',
                kontakt: 'Kontakt',
                impressum: 'Impressum',
                datenschutz: 'Datenschutz',
                shop: 'Shop',
                detailShop: 'Shop',
                newsletter: 'Newsletter',
                search: 'Suche',
                search_long: 'Suchergebnisse für:',
                uhr: 'Uhr',
                bestellen: 'Bestellen',
                bestellung: 'Bestellung',
                vergriffen: 'Vergriffen',
                gratis: 'Für Mitglieder gratis',
                related: 'Weiterführende Inhalte:',
                ausstellungsprogramm: 'Ausstellungsprogramm:'
            },
            cat: {
                alle: 'Alle',
                ausstellung: 'Ausstellung',
                veranstaltung: 'Veranstaltung',
                vitrine: 'Vitrinen Gleis 3/4',
                vorschau: 'Vorschau',
                vorschau_vitrine: 'Vorschau Vitrinen Gleis 3/4',
                editionen: 'Edition',
                publikation: 'Publikation',
                fanartikel: 'Fanartikel',
                jahresgabe: 'Jahresgabe'
            },
            text: {
                search: 'Ihre Suche ergibt keine Treffer. Bitte überprüfen Sie die Schreibweise oder versuchen einen anderen Suchbegriff.',
                anschrift_a: `Kunstverein Harburger Bahnhof
                Im Fernbahnhof über Gleis 3\u200A/\u200A4`,
                anschrift_b: `
                Hannoversche Straße 85
                D\u201321079 Hamburg`,
                jahresgaben: `Jahresgaben sind Editionen und Unikate von aufstrebenden Künstler:innen aus dem Programm des Kunstverein Harburger Bahnhof, 
                die unsere Mitglieder exklusiv zu günstigen Konditionen erwerben können. Mit dem Kauf einer Jahresgabe leisten Sie einen nachhaltigen Beitrag 
                zur Unterstützung der Arbeit der Künstler:innen und des Kunstvereins zu gleichen Teilen. 
                Die Mitgliedschaft im Kunstverein Harburger Bahnhof ist Voraussetzung für den Erwerb einer Jahresgabe. 
                Für Fragen zur Mitgliedschaft, zu einzelnen Arbeiten oder zur Vereinbarung eines individuellen Besichtigungstermins wenden Sie sich gerne an`,
                unikat: 'Bitte wählen Sie ein Unikat:',
                besichtigung: 'Für individuelle Besichtigungstermine und weitere Fragen melden Sie sich gerne unter',
                bestellung: `Nach dem Abschluss Ihrer Bestellung bekommen Sie eine Auftragsbestätigung an die angegebene E-Mail Adresse. 
                Bezahlung per Rechnung. Nachdem der Rechnungsbetrag auf unserem Konto eingegangen ist, versenden wir den bestellten Artikel an die angegebene Adresse. 
                Die Versandkosten gehen zu Lasten der Käufer:innen. Bestellungen werden in der Reihenfolge ihres Eingangsdatums berücksichtigt und sind verbindlich.`,
                korrektur: 'Bitte korrigieren Sie die markierten Felder.',
                danke_a: 'Vielen Dank!',
                danke_b: 'In Kürze erhalten Sie eine Bestätigungs E-Mail.',
                mitgliedschaft: `Mit ihrer Mitgliedschaft im Kunstverein Harburg Bahnhof e.V. unterstützen Sie die Arbeit eines jungen lebendigen Kunstvereins. 
                Seit 1999 widmet sich der Kunstverein mit Sitz im historischen Wartesaal der 1. & 2. Klasse im Harburger Fernbahnhof der zeitgenössischen nationalen und internationalen Kunst. 
                Ihre Mitgliedschaft im Kunstverein Harburger Bahnhof steht für die Anerkennung eines diskursiven Raums für aktuelle Kunst. 
                Mit Ihrem Engagement leisten Sie einen Beitrag dazu, dass wir unsere Arbeit kontinuierlich fortsetzen können, um weiterhin außergewöhnliche künstlerische Projekte an einem besonderen Ort zu realisieren.`,
                vorteile: 'Neben diesem ideellen Beitrag bietet Ihnen eine Mitgliedschaft im Kunstverein Harburger Bahnhof verschiedene Vorteile:',
                mitgliedschaft_list: `
                Unser Programm speziell für Mitglieder bietet Ihnen die einmalige Möglichkeit, bei bspw. Atelierbesuchen und Kurzreisen in direkten Austausch mit Künstler:innen und Ausstellungsmacher:innen zu kommen.
                *list*
                Die Möglichkeit des Erwerbs limitierter Jahresgaben international renommierter Künstler:innen ist exklusiv unseren Mitgliedern vorbehalten.
                *list*
                Sie erhalten die Einladungen zu allen Eröffnungen und Veranstaltungen des Kunstvereins bequem per Post.
                *list*
                Zudem informieren Sie unsere Mitgliederbriefe über Entwicklungen und Projekte des Kunstvereins.
                *list*
                Sie haben jederzeit die Möglichkeit zu einem persönlichen Austausch mit dem Vorstand sowie Mitsprache und Stimmberechtigung bei den jährlichen Vereinsversammlungen.
                *list*
                Europaweit erhalten Sie freien Eintritt zu den Ausstellungen aller Kunstvereine, die der AdKV (Arbeitsgemeinschaft deutscher Kunstvereine, rund 300 Häuser) angehören.
                `
            },
            order: {
                name: 'Name',
                vorname: 'Vorname',
                email: 'E-Mail', 
                mitgliedsnummer: 'Mitgliedsnummer',
                versandart: 'Versandart',
                versand: 'Versand',
                zusatz: 'Zusatz / Firma',
                strasse: 'Straße / Hausnummer',
                postleitzahl: 'Postleitzahl',
                stadt: 'Stadt',
                ort: 'Ort',
                telefon: 'Telefon',
                land: 'Land',
                abholung: 'Abholung',
                bemerkung: 'Bemerkung',
                error: 'Bitte korrigieren Sie die markierten Felder.'
            },
            member: {
                self: 'Ich möchte Mitglied werden',
                gift: 'Ich möchte eine Mitgliedschaft verschenken',
                voll: 'Volle Mitgliedschaft: 50 EUR',
                ermaessigt: 'Ermäßigte Mitgliedschaft: 25 EUR (Künstler:innen, Studierende, Schüler:innen, Azubis, Erwerbslose)',
                familie: 'Familienmitgliedschaft: 60 EUR',
                foerder: 'Fördermitgliedschaft: Betrag (ab 300 EUR)',
                rechnungsadresse: 'Rechnungsadresse abweichend',
                sepa: 'Ich möchte ein SEPA-Lastschriftmandat erteilen (Wenn Sie uns kein SEPA-Lastschriftmandat erteilen, erfolgt die Bezahlung des Mitgliedsbeitrags auf Rechnung)',
                member_list: `
                Bitte beachten Sie, dass eine Mitgliedschaft für ein Kalenderjahr gilt. Die Kündigung muss schriftlich bis zum 1.10. eines Jahres eingereicht werden. Alle Mitgliedsbeiträge sind steuerlich absetzbar.
                *list*
                Ich willige ein, dass der Kunstverein Harburger Bahnhof e.V. die in der Beitrittserklärung erhobenen personenbezogenen Daten (Name, Vorname, Adresse, Emailadresse, Telefonnummer, Bankverbindung) ausschließlich zum Zwecke der Mitgliederverwaltung, des Beitragseinzuges und der Übermittlung von Vereinsinformationen verarbeitet und nutzt. Eine Datenübermittlung an Dritte findet nicht statt außer im Falle Ihrer Emailadresse, da wir zum Versenden des Newsletters einen Anbieter konsultiert haben und die Leitung der Daten über den Server unseres Anbieters GetResponse, Arkońska 6, A3, 80-387 Gdańsk, Polen erfolgt.
                *list*
                Bei Beendigung der Mitgliedschaft werden die personenbezogenen Daten gelöscht, soweit sie nicht entsprechend der gesetzlichen Vorgaben aufbewahrt werden müssen. Jedes Mitglied hat im Rahmen der Vorgaben des Bundesdatenschutzgesetzes/Datenschutzgrundverordnung das Recht auf Auskunft über die personenbezogenen Daten, die zu seiner Person bei der verantwortlichen Stelle gespeichert sind. Außerdem hat das Mitglied, im Falle von fehlerhaften Daten, ein Korrekturrecht.
                `,
                sepa_list: `
                Kunstverein Harburger Bahnhof, Hannoversche Str. 85, 21079 Hamburg
                *list*
                Gläubiger-Identifikationsnummer: DE19ZZZ00001498374
                *list*
                Mandatsreferenz (wird nach Beitritt mitgeteilt)
                *list*
                Ich / Wir ermächtige/n den Kunstverein Harburger Bahnhof e.V. Zahlungen von 
                meinem/unserem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein 
                Kreditinstitut an, die vom Kunstverein Harburger Bahnhof e.V. auf mein Konto 
                gezogenen Lastschriften einzulösen.
                *list*
                Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, 
                die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem 
                Kreditinstitut vereinbarten Bedingungen.
                `
            }
        },
        en: {
            title: {
                undefined: 'undefined',
                home: 'Kunstverein Harburger Bahnhof',
                programm: 'Program',
                detail: 'Program',
                aktuell: 'Current',
                archiv: 'Archive',
                jahresgaben: 'Jahresgaben',
                detailGift: 'Jahresgaben',
                ueber: 'About',
                agenda: 'Agenda',
                team: 'Team',
                geschichte: 'History',
                mitgliedschaft: 'Membership',
                mitgliedwerden: 'Become a Member',
                mitgliedsantrag: 'Membership Form',
                kontakt: 'Contact',
                impressum: 'Imprint',
                datenschutz: 'Privacy policy',
                shop: 'Shop',
                detailShop: 'Shop',
                newsletter: 'Newsletter',
                search: 'Search',
                search_long: 'Search results for:',
                uhr: '',
                bestellen: 'Order',
                bestellung: 'Order',
                vergriffen: 'Out of stock',
                gratis: 'Free for Members',
                related: 'Related contents:',
                ausstellungsprogramm: 'Exhibition program:'
            },
            cat: {
                alle: 'All',
                ausstellung: 'Exhibition',
                veranstaltung: 'Event',
                vitrine: 'Showcases Platform 3/4',
                vorschau: 'Preview',
                vorschau_vitrine: 'Preview Showcases Platform 3/4',
                editionen: 'Edition',
                publikation: 'Publication',
                jahresgabe: 'Jahresgabe',
                fanartikel: 'Merch'
            },
            text: {
                search: 'Unfortunately, we couldn\'t find any results that match your search. Please check the spelling or try another keyword.',
                anschrift_a: `Kunstverein Harburger Bahnhof
                c/o Harburg train station above platform 3\u200A/\u200A4`,
                anschrift_b: `
                Hannoversche Straße 85
                D\u201321079 Hamburg`,
                unikat: 'Please select a variant:',
                jahresgaben: `Jahresgaben are editions and unique works by promising artists who have been part of the program of the Kunstverein Harburger Bahnhof. 
                Our members have the exclusive opportunity to acquire Jahresgaben at favorable conditions. With the purchase of these editions, you make a lasting contribution to supporting the work of the artists
                and the Kunstverein in equal parts. Please note that you must be a member to acquire one of our Jahresgaben. 
                For enquiries about membership, specific works or to arrange an individual viewing appointment, please feel free to contact`,
                besichtigung: 'For individual viewing appointments and further questions, please contact us at',
                bestellung: `After completing your order, you will receive an order confirmation 
                to the provided e-mail address. Payment by invoice. After the invoice 
                amount has been credited to our account, we will ship the ordered item 
                to the indicated address. The shipping costs will be charged to the 
                buyer. Orders will be considered in the order of their date of 
                entry and are binding.`,
                korrektur: 'Please correct the marked boxes.',
                danke_a: 'Thank you very much!',
                danke_b: 'You will receive a confirmation email shortly.',
                mitgliedschaft: `Your membership in the Kunstverein Harburg Bahnhof e.V. supports the work of a young and dynamic art institution. 
                Based in the historic 1st and 2nd class waiting room of Harburg\u004bs long-distance train station, 
                the Kunstverein has been dedicated to contemporary national and international art since 1999. 
                Your membership in the Kunstverein Harburger Bahnhof stands for the recognition of a discursive space for contemporary art. 
                With your commitment you contribute to and enable our continuous pursuit to realize extraordinary artistic projects in a special place.`,
                vorteile: 'In addition to this idealistic contribution, membership in the Kunstverein Harburger Bahnhof offers you various advantages:',
                mitgliedschaft_list: `
                Our programme especially for members offers you the unique opportunity to get into direct contact with artists and exhibition makers, for example through studio visits and short excursions.
                *list*
                You have the opportunity to purchase limited editions (Jahresgaben) by internationally renowned artists, reserved exclusively for our members.
                *list*
                You will receive invitations to all openings and events of the Kunstverein conveniently by mail.
                *list*
                In addition, our newsletter informs you about recent developments and projects of the Kunstverein.
                *list*
                You have the opportunity for a personal exchange with the board of directors at any time, as well as a voice and vote at the annual association meetings.
                *list*
                Europewide, you receive free admission to the exhibitions of all art associations belonging to the AdKV (Arbeitsgemeinschaft deutscher Kunstvereine, about 300 institutions in total).
                `
            },
            order: {
                name: 'Last Name',
                vorname: 'First Name',
                email: 'E-Mail', 
                mitgliedsnummer: 'Membership number',
                versandart: 'Shipping method',
                versand: 'Shipping',
                zusatz: 'c/o or Company',
                strasse: 'Street / No.',
                postleitzahl: 'Postleitzahl',
                ort: 'Postal Code',
                stadt: 'City',
                telefon: 'Phone',
                land: 'Country',
                abholung: 'Pick-up',
                bemerkung: 'Annotations',
                error: 'Please correct the marked boxes.'
            },
            member: {
                self: 'I want to become a member',
                gift: 'I want to gift a membership',
                voll: 'Full membership: 50 EUR',
                ermaessigt: 'Reduced membership: 25 EUR (artists, students, trainees, unemployed)',
                familie: 'Family membership: 60 EUR',
                foerder: 'Supporting membership: Amount (from 300 EUR)',
                rechnungsadresse: 'Billing Address (if different than above)',
                sepa: 'I would like to issue a SEPA direct debit mandate (If you do not issue us with a SEPA direct debit mandate, payment of the membership fee will be made by invoice)',
                member_list: `
                Please note that a membership is valid for one calendar year. 
                Notice of cancellation must be submitted in writing by October 1 of 
                each year. All membership fees are tax deductible.
                *list*
                I agree that the Kunstverein Harburger Bahnhof e.V. processes and 
                uses the personal data collected in the declaration of membership 
                (surname, first name, address, email address, telephone number, 
                bank details) exclusively for the purpose of membership administration, 
                collection of membership fees and transmission of information 
                about the Kunstverein. No data is transferred to third parties except in the case 
                of your email address, as we have consulted a provider to send the 
                newsletter and the data is routed via the server of our provider 
                GetResponse, Arkońska 6, A3, 80-387 Gdańsk, Poland.
                *list*
                Upon cancellation of the membership, the personal data will be 
                deleted, unless they have to be stored in accordance with the 
                legal requirements. Within the framework of the provisions of the 
                Federal Data Protection Act / General Data Protection Regulation, 
                every member has the right to be informed about the personal 
                data stored about them by the responsible body. In addition, 
                the member has a right of correction in the event of incorrect data.               
                `,
                sepa_list: `
                Kunstverein Harburger Bahnhof, Hannoversche Str. 85, 21079 Hamburg
                *list*
                Creditor identification number: DE19ZZZ00001498374
                *list*
                Mandate reference will be communicated after joining
                *list*
                I / We authorise the Kunstverein Harburger Bahnhof e.V. to collect 
                payments from my / our account by direct debit. At the same time, I 
                instruct my bank to accept the direct debits made to my account by 
                the Kunstverein Harburger Bahnhof e.V.
                *list*
                Note: I can request a refund of the debited amount within eight weeks, 
                starting from the debit date. The conditions agreed with my credit 
                institution apply.
                `
            }
        },
}
