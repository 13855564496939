<template>
  <div id="annualGift">
    <p>
      <span>{{ $t('text.jahresgaben') }}</span> <a href="mailto:info@kvhbf.de">info@kvhbf.de</a>.
      <router-link class="membership na" :to="{name:'mitgliedschaft'}"><button><h3>{{ $t('title.mitgliedwerden') }}</h3></button></router-link>
    </p>
    <div>
      <ul v-for="(year, index) in years" :key="index">
        <li v-bind:class="{ active: year.year === this.yearFilter }">
           <div class="sp"><button @click="loadYear(year)"><h3>{{year.year}}</h3></button><img v-if="!year.loaded" class="spinner" src="~@/assets/spinner.svg"></div>
           <masonry v-if="year.loaded && show" :cols="{default: 2, 1439: 1, 1023: 2, 767: 1}" :gutter="10">
              <Item v-for="(item, index) in loadedGifts" :event="item" type="AnnualGift" :key="index"></Item>
           </masonry>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import functions from '@/functions.js'
import Item from '@/components/snippets/Item'
import {store} from '@/centralStore'
import _ from 'lodash'

export default {
    inject: ["mq"],
    components: {
        Item
    },
    data(){
      return {
        gifts: [],
        loadedGifts: [],
        yearFilter: null,
        years: [],
        show: false,
        store: store
      }
    },
    methods: {
      render(obj) {
        this.gifts.map(el => el.loaded = false)
        obj.loaded = true
        this.loadedGifts = this.loadGifts
      },
      loadYear(obj){
        if(obj === undefined) return
        this.years.map(el => el.loaded = false)
        this.yearFilter = obj.year
         api.get('gifts-year/' + obj.year)
            .then(response => {
              obj.gifts = response.data.gifts
              obj.loaded = true
              this.loadedGifts = obj.gifts
              this.updateQueryParams()
        })
      },
      updateQueryParams() {
        const query = {}

        if (this.yearFilter != null) {
          query.year = this.yearFilter
        }

        if (!_.isEqual(this.$route.query, query)) {
          if (store.tinkerRoute.fullPath === '/jahresgaben')
            this.$router.replace({ query: query })
          else
            this.$router.push({ query: query })
        }
        
      },
      sortByDate: functions.sortByDate
    },
     watch: {
      
      '$route.query.year'() {
          this.loadYear(this.years.filter(a => a.year === parseInt(this.$route.query.year))[0])
      }
  
    },
    computed: {
       loadGifts() {
        return this.gifts.filter(a => a.loaded).flatMap( a => a.gifts)
      }
    },
    updated: function () {
      this.$nextTick(function () {
        //const anchor = document.querySelector('div > ul:first-of-type');
        window.scrollTo(0, 0)
        this.show = true
        this.updateQueryParams()
      })
    },
    mounted(){
      /*
        api.get('gifts/')
            .then(response => {
            this.gifts = this.sortByDate(response.data.gifts, "year")
            this.gifts = this.gifts.map((a, index) => {
              return { 
                year: a.year,
                gifts: this.sortByDate(a.gifts, "start_date"),
                loaded: (index === 0) ? true : false
              }
            })
            this.loadedGifts = this.loadGifts
            
        })
        */
        api.get('gifts-year/')
            .then(response => {
              if ('year' in this.$route.query) {
                this.yearFilter = parseInt(this.$route.query.year)
              }
              const data = response.data.year_range.reverse()
              const filteredYear = (data.includes(this.yearFilter)) ? this.yearFilter : data[0]
              this.yearFilter = filteredYear

              api.get('gifts-year/' + filteredYear )
                .then(response => {
                  const loaded = response.data.gifts
                  this.loadedGifts = loaded
                  
                  this.years = data.map((a, index) => {
                    return {
                      year: a,
                      gifts: (index === 0) ? loaded : null,
                      loaded: (a === filteredYear) ? true : false
                    }
                  })
              })
            })
    }

}

</script>

<style scoped>
.desktop #annualGift {
  display: flex;
}

.desktop #annualGift > p {
 padding-right: .625rem;
}

.desktop #annualGift > p, .desktop #annualGift > div { 
  width: 50%;
}

.desktop.xxl #annualGift > p { 
  width: 33.33%;
}

.desktop.xxl #annualGift > div { 
  width: 66.66%;
}

#annualGift > p {
  margin-bottom: calc( var(--margin-yellow) + .4375rem);
}

#annualGift > div > ul:not(:last-of-type){
  margin-bottom: calc(var(--margin-green) + .125rem);
}

li.active > div:not(.sp){
  margin-top: 1.625rem;
}

.active button{
  background-color: var(--text);
  color: var(--bg);
}

div > ul:not(:last-of-type) > li.active > div:not(.sp) {
  margin-bottom: calc(var(--margin-yellow) + .625rem + .25rem);
}

li.active button ~ div{
  margin-top: 1.0625rem;
}


a.membership {
 display: block;
 margin-top: calc(var(--margin-green) - .0625rem);
}

a.membership:hover button {
 color: var( --bg);
 background: var(--accent);
}

a.membership button{
 color: var( --accent);
 border-color: var(--accent);
}

li.active > div {
  display: flex;
}

li.active > div > img{
  margin-left: .625rem;
}


li:not(.active) > div > img{
  display: none;
}

.spinner {
  height: var(--button);
  width: auto;
  animation: spinner infinite 1s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>