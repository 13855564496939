<template>
    <footer>
        <p>{{ $t('text.anschrift_a') }}
        <span>{{ $t('text.anschrift_b') }}</span></p>
        <p v-if="opening && ( store.menuVisible || mq.lgPlus )"><span v-html="getAttr(opening ,'text')"></span></p>
        <p class="announcement" v-if="announce && (store.menuVisible || mq.lgPlus ) && getLocale() == 'de-DE'" v-html="getAttr(announce ,'text')"></p>
        <p class="announcement" v-else-if="announce && (store.menuVisible || mq.lgPlus )" v-html="getAttr(announce ,'text')"></p>
    </footer>


</template>

<script>
import api from '@/api'
import functions from '@/functions.js'
import {store} from '@/centralStore';
export default {
    inject: ["mq"],
    data(){
        return {
            store: store,
            opening: null,
            announce: null
        }
    },
    methods :{
        toggleMenu(){
            this.store.menuVisible = !this.store.menuVisible;
        },
        getAttr: functions.getAttr,
        getLocale: functions.getLocale
    },
    mounted(){
        api.get('administration/')
            .then(response => {
                this.opening = response.data.data.oeffnungszeiten
                this.announce = response.data.data.aktuelles
            })

    }
}
</script>


<style scoped>

footer {
    margin-top: var(--margin-yellow);
    padding-bottom: .125rem;
}

#content.mobile footer {
    margin-top: calc(var(--margin-pink) + 0.125rem );
}

footer p {
    white-space: pre-line;
}

footer p:first-of-type {
    margin-bottom: .1875rem;
    font-feature-settings: "ss01" 1, "ss02" 1, "ss04" 1; 
    font-variation-settings: "wght" 105, "hght" 491, "prtn" 500;
}

.mobile footer p:first-of-type {
    font-variation-settings: "wght" 90, "hght" 491, "prtn" 500;
}

footer p:first-of-type span {
    font-feature-settings: "ss01" 0, "ss02" 0, "ss04" 0; 
}

.mobile footer p:first-of-type span {
    font-variation-settings: "wght" 90, "hght" 491, "prtn" 0;
}

footer p.announcement {
    margin-top: calc(var(--margin-yellow) - .0625rem );
    color: var(--accent);
}

#content.mobile footer p.announcement {
    margin-top: calc(var(--margin-yellow) + .0625rem );
}

#content.mobile nav > footer {
    margin-top: 0;
}

#content.mobile footer p:first-of-type{
    margin-bottom: .3125rem;
}

#content.mobile.sm footer p:first-of-type{
    margin-bottom: .25rem;
}

#content.mobile.xs footer p:first-of-type{
    margin-bottom: .375rem;
}


</style>
