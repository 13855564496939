export const datetimeFormats = {
    'en-US': {
      year: {
            year: 'numeric'
      },
      short: {
        month: 'numeric', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      },
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    },
    'de-DE': {
      year: {
        year: 'numeric'
      },
      short: {
        month: 'numeric', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      },
      currency: {
        style: 'currency',
        currency: 'EUR'
      }
    }
}